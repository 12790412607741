@import "../common/accordion";

.accordion {
	min-height: calc(100vh - 149px);

	article {
		font-family: $secondary;

		h3 {
			font-weight: 700;
		}
	}
}
