.careers-landing {
	main {
		background-color: $cyan;
		padding-top: 50px;

		@media only screen and (max-width: $mobile) {
			background-color: $white;

			& > .wrapper {
				padding: 0;
			}

			& > .flex {
				display: block;
			}
		}
	}

	article {
		margin-right: 2 * $col-margin;
		width: $col-article-width;
		padding-left: $col-padding;

		@media only screen and (max-width: $mobile) {
			margin-right: 0;
			padding: 0 15px;
			width: auto;
		}
	}

	aside {
		width: $col-aside-width;

		@media only screen and (max-width: $mobile) {
			padding: 30px 15px;
			width: auto;
		}
	}

	.teasers {
		@media only screen and (max-width: $mobile) {
			background-color: $cyan;
			margin: 0 -15px;
			padding: 30px 15px;

			.content {
				p {
					display: block;
				}
			}
		}
	}

	h2 {
		@include font-size(4.8);
		letter-spacing: -0.5px;
		line-height: 130%;
		margin: 0 0 20px 0;
	}
}

.careers-franchise {
	section {
		padding: 50px 0;

		.accordion {
			section {
				padding: 10px 0;
			}
		}

		.wrapper {
			max-width: $col10;

			@media only screen and (max-width: $sml-desktop) {
				max-width: $col8;
			}

			@media only screen and (max-width: $lrg-tablet) {
				max-width: $col10;
			}

			@media only screen and (max-width: $mobile) {
				max-width: none;
			}
		}

		&:nth-child(even) {
			background-color: $bkgrey;
		}

		h2 {
			@include font-size(4.8);
			letter-spacing: -0.5px;
			line-height: 130%;
			margin: 0 0 20px 0;
		}

		h3,
		h4 {
			@include font-size(2.4);
			font-family: $secondary;
			font-weight: 700;
			letter-spacing: -0.5px;
			line-height: 30px;
			margin: 50px 0 20px;
		}

		h4 {
			@include font-size(1.6);
			line-height: 28px;
		}

		.career-story {
			font-family: $secondary;
			margin: 50px 0 0;
			width: 100%;

			&:nth-child(2) {
				margin-left: $col-margin;

				@media only screen and (max-width: $mobile) {
					margin-left: 0;
				}
			}

			h3 {
				@include font-size(2.2);
				font-family: $primary;
				font-weight: 400;
				line-height: 34px;
				margin: 25px 0 10px;
			}

			.location {
				@include font-size(1);
				letter-spacing: 2px;
				line-height: 20px;
				text-transform: uppercase;
			}
		}

		.flex {
			@include justify-content(space-between);

			@media only screen and (max-width: $mobile) {
				display: block;
			}
		}

		.main {
			max-width: $col7;
			width: $col7;

			@media only screen and (max-width: $mobile) {
				max-width: none;
				width: auto;
			}
		}

		.aside {
			width: $col3;

			@media only screen and (max-width: $mobile) {
				width: auto;
			}
		}

		.leadin {
			@include font-size(2.2);
			color: $blue;
			line-height: 34px;
		}

		.video-thumb {
			margin: 50px 0;
		}

		.asterisk {
			@include font-size(1.2);
			font-family: $secondary;
			line-height: 20px;
		}

		.markets {
			margin: 30px 0;

			@media only screen and (max-width: $mobile) {
				margin: 30px;
			}
		}
	}
}

.careers-corporate {
	h2 {
		@include font-size(4.8);
		letter-spacing: -0.5px;
		line-height: 130%;
		margin: 0 0 20px 0;
	}

	h4 {
		@include font-size(1.6);
		font-family: $secondary;
		font-weight: 600;
		line-height: 28px;
		margin-top: 30px;
	}

	.accordion {
		margin: 50px 0 75px;
		background-color: transparent;
		padding: 0;

		section {
			@include font-size(1.6);
			font-family: $secondary;
			line-height: 24px;
			padding-top: 10px;
		}

		.btn {
			margin-bottom: 30px;
			margin-top: 30px;

			& + .btn {
				margin-left: 15px;
			}
		}

		.toggle span[tabindex]:focus {
			font-weight: normal;
			outline: auto 5px;
		}
	}

	.note {
		@include font-size(1.2);
		font-family: $secondary;
		line-height: 20px;
	}

	article {
		.blurb,
		.blurb-alt {
			@include font-size(2.2);
			line-height: 34px;
			margin: 0 0 20px 0;
		}

		.blurb {
			color: $blue;
		}

		& > section {
			padding: 75px 0;

			&:nth-child(odd) {
				background-color: $bkgrey;
			}
		}
	}

	section {
		& > .wrapper {
			width: $col7;

			@media only screen and (max-width: $lrg-tablet) {
				width: $col8;
			}

			@media only screen and (max-width: $mobile) {
				width: auto;
			}
		}
	}

	.video-wrapper {
		margin-bottom: 30px;
	}
}

.centered {
	text-align: center;

	@media only screen and (max-width: $mobile) {
		text-align: left;
	}
}
