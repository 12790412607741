article,
.lightbox,
aside {
	ul {
		font-family: $secondary;
		list-style-image: url("img/bullet.svg");
		list-style-position: inside;
		margin: 0 25px 0;
		padding: 0 0 0 20px;

		li {
			margin: 0 0 10px 30px;
			text-indent: -21px;
		}

		&.social-icons {
			li {
				text-indent: 0;
				margin: 0 5px 0 0;

				&:before {
					display: none;
				}
			}
		}

		&.events {
			@include flex-wrap(wrap);
			display: flex;
			list-style: none;
			margin: 25px 0 -30px 0;
			padding: 0;

			@media only screen and (max-width: $mobile) {
				display: block;
			}

			.date {
				@include font-size(1.2);
				display: block;
				font-family: $secondary;
				line-height: 20px;
				margin-bottom: 10px;
				text-transform: uppercase;
			}

			li {
				margin: 0 0 45px 0;
				padding: 0 10px 0 0;
				text-indent: 0;
				width: $col4;

				&:before {
					display: none;
				}

				a {
					@include font-size(2.2);
					font-family: $primary;
					line-height: 34px;
				}
			}
		}

		&.faq-toggle,
		&.history-toggle {
			@include font-size(1.2);
			font-family: $secondary;
			line-height: 20px;
			list-style: none;
			margin: 0 -10px;
			padding: 0;
			width: $col10;

			@media only screen and (max-width: $mobile) {
				display: none;
			}

			a {
				border: 0;
				color: $dkgrey;
				display: block;
			}

			li {
				margin: 10px 0;
				padding: 0 10px;
				position: relative;
				text-indent: 0;

				&:before {
					display: none;
				}

				&.current {
					background-color: $white;
					font-weight: 600;

					:after {
						border: solid transparent;
						border-color: rgba(255, 255, 255, 0);
						border-left-color: $white;
						border-width: 10px;
						content: "";
						height: 0;
						left: 100%;
						margin-top: -10px;
						pointer-events: none;
						position: absolute;
						top: 50%;
						width: 0;
					}

					a {
						color: $red;
					}
				}
			}
		}

		&.history-toggle {
			margin-top: 50px;

			li {
				text-indent: 0;
				margin: 10px 0;
				line-height: 1.5;
			}
		}

		&.recipe-specifics {
			@include font-size(1.2);
			font-family: $secondary;
			letter-spacing: 2px;
			line-height: 20px;
			list-style: none;
			margin: 5px 0 0 $col-margin;
			padding: 0 0 0 151px;
			text-transform: uppercase;

			@media only screen and (max-width: $mobile) {
				margin: 0;
				padding: 20px 0 0;
			}

			li {
				display: inline-block;
				margin: 0 40px 0 0;
				padding: 0;
				text-indent: 0;

				&:before {
					display: none;
				}

				@media only screen and (max-width: $mobile) {
					margin: 0 20px 20px 0;
				}
			}

			span {
				@include font-size(2.2);
				color: $blue;
				display: inline-block;
				line-height: 34px;
				margin-right: 5px;
				vertical-align: sub;
			}
		}

		&.zip-codes {
			@include flex-wrap(wrap);
			display: flex;
			margin: 40px auto;
			width: $col9;

			li {
				margin: 0 35px 15px 35px;
				text-indent: 0;

				&:before {
					display: none;
				}
			}
		}
	}

	ol {
		li {
			margin-left: 20px;
			padding-left: 20px;
		}
	}
}

ul {
	&.history-toggle {
		@include font-size(1.2);
		font-family: $secondary;
		line-height: 20px;
		list-style: none;
		margin: 0 -10px;
		margin-top: 50px;
		padding: 0;
		width: $col10;

		@media only screen and (max-width: $mobile) {
			display: none;
		}

		a {
			border: 0;
			color: $dkgrey;
			display: block;

			&:focus {
				outline: 0;
			}
		}

		li {
			line-height: 1.5;
			margin: 10px 0;
			padding: 0 10px;
			position: relative;
			text-indent: 0;

			&:before {
				display: none;
			}

			&.current {
				background-color: $white;
				font-weight: 600;

				:after {
					border: solid transparent;
					border-color: rgba(255, 255, 255, 0);
					border-left-color: $white;
					border-width: 10px;
					content: "";
					height: 0;
					left: 100%;
					margin-top: -10px;
					pointer-events: none;
					position: absolute;
					top: 50%;
					width: 0;
				}

				a {
					color: $red;
				}
			}
		}
	}

	&.no-bullets {
		margin: 0;
		padding-left: 0;

		ul {
			margin-left: 0;
		}

		li {
			font-family: $primary;
			margin: 0 0 15px 0;
			padding: 0;
			text-indent: 0;

			li {
				@include font-size(1.2);
				font-family: $secondary;
				line-height: 20px;
				margin: 10px 0 10px 25px;
			}

			&:before {
				display: none;
			}
		}
	}
}
