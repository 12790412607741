.lightbox {
	@extend .wrapper;
	padding: 0;

	.icon-close {
		@include font-size(2);
		border: 0;
		color: $red;
		right: 10px;
		top: 10px;

		&:active {
			top: 10px;
		}

		&:hover {
			cursor: pointer;
		}
	}

	& > div {
		border-radius: 15px;
	}

	.address-list {
		list-style: none;
		margin: 0;
		padding: 0;

		label {
			display: inline-block;
			margin-left: 20px;
		}

		li {
			margin: 0 0 20px 0;
			text-indent: 0;

			&:before {
				display: none;
			}
		}
	}
}

.franchise-lb,
.lightbox-frame,
.leaving-cfa {
	background-color: $white;
	margin: 0 auto;
	padding: 30px 50px;
	position: relative;
	width: $col9;

	@media only screen and (max-width: $mobile) {
		margin: 0;
		padding: 20px;
		width: $col11;

		.flex {
			display: block;
		}
	}

	.btn {
		margin: 20px 0 0 0;
	}

	.state {
		@include font-size(4.8);
		letter-spacing: -0.5px;
		line-height: 130%;
		margin-bottom: 30px;
		text-align: center;

		img {
			margin-bottom: 20px;
		}
	}

	.flex {
		& > div,
		& > form {
			width: $col6;

			@media only screen and (max-width: $mobile) {
				width: auto;
			}
		}

		.confirmation {
			padding: 60px 130px;
			margin-right: 0;
			width: 100%;

			@media only screen and (max-width: $mobile) {
				width: auto;
			}
		}

		& > div {
			margin-right: 2 * $col-margin;

			@media only screen and (max-width: $mobile) {
				margin: 0;
			}
		}

		& > form {
			padding-top: 10px;

			@media only screen and (max-width: $mobile) {
				width: $col11;
			}
		}
	}

	.content {
		@include font-size(2.2);
		line-height: 34px;

		@media only screen and (max-width: $sml-desktop) {
			font-size: 16px;
			line-height: 28px;
		}

		h5 {
			@include font-size(1.6);
			font-family: $secondary;
			font-weight: 600;
			line-height: 28px;
			margin: 0 0 10px;
		}

		ul {
			@include font-size(1.6);
			line-height: 28px;
		}
	}
}

.confirmation {
	line-height: 1.8;
	text-align: center;

	h3 {
		@include font-size(2.2);
		color: $blue;
		line-height: 34px;
		margin: 0 0 20px 0;
	}

	p {
		margin: 25px 0;
	}
}

.mfp-container {
	@media only screen and (max-width: $mobile) {
		padding: 0;
	}
}

.leaving-cfa {
	@extend .base-modal;
	@extend .leaving-cfa-modal;
}
