// sass-lint:disable no-qualifying-elements

.email-insiders {
	.top-hero {
		@include align-items(center);
		display: flex;

		&:before {
			@include scrim($scrim);
		}
	}

	main {
		.flex {
			@media only screen and (max-width: $mobile) {
				display: block;

				&.wrapper {
					padding: 0;
				}
			}
		}

		.wrapper {
			max-width: $col10;

			@media only screen and (max-width: $mobile) {
				max-width: none;
			}
		}

		form {
			padding: 50px 0;
			width: $col5;

			@media only screen and (max-width: $mobile) {
				padding: 50px 30px;
				width: auto;
			}
		}
	}

	article {
		margin-right: 2 * $col-margin;
		padding: 50px 0;
		width: $col6;

		@media only screen and (max-width: $mobile) {
			margin: 0;
			padding: 50px 30px 0;
			width: auto;
		}

		h1 {
			@include font-size(2.8);
			letter-spacing: 0;
			line-height: 150%;
		}
	}
}

.contact-support {
	.top-hero {
		@include align-items(center);
		display: flex;

		&:before {
			@include scrim($scrim);
		}
	}

	h2 {
		@include type-h2();
		color: $vis-blue;
	}

	.right {
		h2 {
			@include type-h4();
			color: $vis-grey;
			margin: 13px 0 10px;
		}
	}

	.flex {
		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}

	.visit {
		padding: 50px 0;

		@media only screen and (max-width: $mobile) {
			background-color: $cyan;
		}

		.left {
			margin: 0 (2 * $col-margin) 0 (4 * $col-margin);
			width: $col6;
		}

		.right {
			width: $col6;

			@media only screen and (max-width: $mobile) {
				display: none;
			}
		}
	}

	div.faq {
		@extend .listening;
		padding-bottom: 50px;

		.left {
			margin: 0 $col-margin 0 (4 * $col-margin);
			width: $col4;
		}

		.right {
			padding-top: 80px;
			margin-left: 0;
			margin-right: $col1;
			width: $col8;

			@media only screen and (max-width: $mobile) {
				margin-top: 50px;
			}
		}

		@media only screen and (max-width: $mobile) {
			h2 {
				margin: 0 15px;
			}
		}
	}

	.listening,
	.visit,
	div.faq {
		.left,
		.right {
			@media only screen and (max-width: $mobile) {
				margin-left: 0;
				margin-right: 0;
				width: auto;
			}
		}
	}

	.listening {
		background-color: $bkgrey;
		padding: 50px 0;
	}

	article {
		background-color: $white;
	}
}

dl {
	&.hide {
		height: 0;
		overflow: hidden;
	}

	&.faq {
		font-family: $secondary;
		line-height: 1.5;

		@media only screen and (max-width: $mobile) {
			background-color: $cyan;
			margin: 0;
			padding: 30px 0;
		}

		dt {
			font-weight: 600;
			margin: 25px 0 0;

			&:before {
				margin-right: 5px;
			}
		}

		dd {
			border-bottom: 1px solid $dkgrey;
			margin: 10px 0;
			padding: 0 0 15px 0;

			@media only screen and (max-width: $mobile) {
				&:last-child {
					border: 0;
				}
			}

			& > p {
				&:first-child {
					&:before {
						margin-right: 5px;
					}
				}
			}
		}

		dt,
		dd {
			@media only screen and (max-width: $mobile) {
				background-color: $cyan;
				margin: 0;
				padding: 10px 25px;
			}
		}
	}
}

div.faq {
	.wrapper {
		@media only screen and (max-width: $mobile) {
			padding: 0;
		}
	}

	h4 {
		@include font-size(2.8);
		background-color: $white;
		border-top: 1px solid $dkgrey;
		display: none;
		letter-spacing: 0;
		line-height: 150%;
		margin: 0;
		padding: 10px;

		@media only screen and (max-width: $mobile) {
			display: block;
		}

		&.toggle {
			color: $red;
			top: 46px;
			z-index: 2000;

			@media only screen and (max-width: $mobile) {
				padding-right: 50px;
				position: relative;
				top: 0;
			}

			&:after {
				@include font-size(1.8);
				content: "\e902";
				font-family: $icon;
				float: right;
				margin: 3px;
				transition: transform 0.25s ease, margin 0.25s ease;

				@media only screen and (max-width: $mobile) {
					float: none;
					position: absolute;
					right: 10px;
					top: 5px;
				}
			}

			&.active {
				box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);

				&:after {
					@include transform(rotatex(-180deg));
					margin-top: 8px;
					transition: transform 0.25s ease, margin 0.25s ease;

					@media only screen and (max-width: $mobile) {
						margin-top: 0;
					}
				}
			}
		}
	}
}
