// sass-lint:disable max-line-length

// General site settings such as font family, font sizes, colors

// Color Variables that stray from colorThemes - avoid adding new color variables here

$dkred: #a4343a;
$grey: #68605a;
$blue: #7ba5de;

$btn-grad: linear-gradient(
	-180deg,
	rgba(254, 67, 108, 0.99) 0%,
	rgba(221, 0, 49, 0.99) 49%,
	#c20726 100%
);
$btn-hover-grad: linear-gradient(
	-180deg,
	rgba(208, 2, 27, 100) 0%,
	rgba(208, 2, 27, 100) 49%,
	#af272f 100%
);
$white-grad: linear-gradient(top, rgba(#fff, 0.9) 0%, rgba(#fff, 1) 100%);
$nav-grad: linear-gradient(to bottom, #fff 90%, rgba(255, 255, 255, 0) 100%);
$nav-grad2: linear-gradient(
	to bottom,
	rgba(255, 255, 255, 0.8) 27%,
	rgba(255, 255, 255, 0) 103%
);
$nav-grad3: linear-gradient(
	to bottom,
	rgba(255, 255, 255, 0.4) -110%,
	rgba(255, 255, 255, 0) 130%
);
$scrim: linear-gradient(
	to bottom,
	rgba(0, 0, 0, 0) 0%,
	rgba(0, 0, 0, 0.25) 100%
);
$scrim2: linear-gradient(
	to bottom,
	rgba(0, 0, 0, 0) 0%,
	rgba(0, 0, 0, 0.5) 100%
);
$scrim3: linear-gradient(
	to bottom,
	rgba(0, 0, 0, 0.25) 0%,
	rgba(0, 0, 0, 0.75) 100%
);
$scrim4: linear-gradient(
	to left,
	rgba(255, 255, 255, 0.1) 46%,
	rgba(255, 255, 255, 1) 60%
);
$scrim5: linear-gradient(
	to top,
	rgba(255, 255, 255, 0.1) 46%,
	rgba(255, 255, 255, 1) 60%
);
$scrim6: linear-gradient(
	to top,
	rgba(0, 0, 0, 0) 70%,
	rgba(0, 0, 0, 0.15) 100%
);

$gray-brown-grad: linear-gradient(
	top,
	rgba(#f4f3f0, 1) 80%,
	rgba(#cdc7be, 1) 100%
);

// Font Families
$primary: "apercu", sans-serif;
$secondary: "apercu", sans-serif;
$icon: "icomoon";

// Widths
$main: 1380px;
$bp1: 1220px;
$bp2: 900px;

// Breakpoints
$desktop: 1439px;
$sml-desktop: 1279px;
$lrg-tablet: 960px;
$mobile: 959px;
$sml-mobile: 650px;

// z-Index Scale
$z1: 100;
$z2: 200;
$z3: 300;
$z4: 400;
$z5: 500;
$z6: 600;
$z7: 700;
$z8: 800;
$z9: 900;
$z10: 1000;

// Grid */
$col1: 5.835%;
$col2: 14.39%;
$col3: 22.95%;
$col4: 31.51%;
$col5: 40.07%;
$col6: 48.63%;
$col7: 57.19%;
$col8: 65.75%;
$col9: 74.31%;
$col10: 82.87%;
$col11: 91.43%;
$col12: 100%;
$col-form-input: 100%;
$col-margin: 2.725%;
$col-margin-quiz-poll: 3.58%;

// Careers
$col-aside-width: 280px;
$col-article-width: 63%;
$col-padding: 2%;
$col-article-content: 54.48%;

// nav
$nav-base-height: 64px;
