// sass-lint:disable max-file-line-count, no-qualifying-elements, variable-for-property, max-line-length

.quiz {
	margin-bottom: 100px;

	.question {
		margin-bottom: 50px;

		&.poll {
			ul {
				margin: 20px 0;
			}

			li {
				&:hover {
					box-shadow: 2px 2px 0px 1px #979797;
					cursor: pointer;
				}
			}
		}

		.vote {
			margin: 0;
			text-align: center;
		}
	}

	.votes {
		@include font-size(1.4);
		margin: -15px 0 0;
	}

	h2 {
		@include font-size(2.4);
		font-weight: 700;
		letter-spacing: -0.5px;
		line-height: 30px;
		margin: 0 100px 20px 102px;

		@media only screen and (max-width: $mobile) {
			margin: 0 0 20px 0;
		}
	}

	figure {
		margin-bottom: 20px;
	}

	ul {
		@include font-size(2.2);
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			&:before {
				display: none;
			}
		}
	}

	.picture-list {
		display: flex;
		margin: 0;

		@media only screen and (max-width: $mobile) {
			@include flex-wrap(wrap);
			@include justify-content(space-between);
		}

		li {
			border: 2px solid #979797;
			line-height: 1.2;
			margin-left: $col-margin;
			text-indent: 0;
			width: $col3;

			@media only screen and (max-width: $mobile) {
				margin: 0 2px 15px;
				max-width: $col6;
				width: 47%;
			}

			.q-content {
				display: flex;
				padding: 10px 5px;

				&:before {
					@include font-size(3);
					color: $faint-grey;
					content: "\e921";
					display: block;
					font-family: $icon;
					margin-right: 10px;
				}
			}

			&.correct {
				.q-content {
					color: #a9c78c;

					&:before {
						@include font-size(2);
						border: 0;
						color: #a9c78c;
						content: "\e920";
						font-family: $icon;
					}
				}
			}

			&.incorrect {
				border-color: $dim-gray;

				.q-content {
					color: $red;

					&:before {
						@include font-size(2);
						border: 0;
						color: $red;
						content: "\e90b";
						font-family: $icon;
					}
				}
			}

			&:hover {
				cursor: pointer;
			}

			&:first-child {
				margin-left: 0;

				@media only screen and (max-width: $mobile) {
					margin: 0 2px 15px;
				}
			}

			img {
				width: 100%;
			}
		}
	}

	.question-list,
	.picture-list {
		li {
			&.disabled,
			&.correct,
			&.incorrect {
				&:hover {
					cursor: auto;
				}
			}

			&.disabled,
			&.incorrect {
				opacity: 0.5;
			}

			&.correct,
			&.selected {
				box-shadow: 4px 4px 0px 1px #8da27a;
				border-color: #a9c78c;

				.q-content {
					color: #a9c78c;

					&:before {
						@include font-size(2);
						border: 0;
						color: #a9c78c;
						content: "\e920";
						font-family: $icon;
					}
				}
			}

			&.correct {
				color: #a9c78c;
			}

			&.incorrect {
				border-color: $dim-gray;
				color: $red;

				.q-content {
					color: $red;

					&:before {
						@include font-size(2);
						border: 0;
						color: $red;
						content: "\e90b";
						font-family: $icon;
					}
				}
			}
		}
	}

	.question-list {
		margin-top: 20px;

		li {
			border: 4px solid #979797;
			display: flex;
			margin: 0 0 15px 0;
			padding: 5px 10px;
			text-indent: 0;
			font-weight: bold;

			&:before {
				@include font-size(2.2);
				color: $faint-grey;
				content: "\e921";
				display: block;
				font-family: $icon;
				line-height: 34px;
			}

			&.correct {
				&:before {
					@include font-size(2);
					border: 0;
					color: #a9c78c;
					content: "\e920";
					font-family: $icon;
				}
			}

			&.incorrect {
				&:before {
					@include font-size(2);
					border: 0;
					color: $red;
					content: "\e90b";
					font-family: $icon;
				}
			}
		}

		&.photo {
			li {
				@include align-items(center);
				line-height: 1.2;
				padding: 0 15px 0 0;

				@media only screen and (max-width: $sml-mobile) {
					display: block;
					padding: 0;
				}

				.poll-img {
					margin: 0 15px 0 0;
					position: relative;
					width: $col3;

					@media only screen and (max-width: $sml-mobile) {
						margin: 0;
						width: $col12;

						img {
							width: 100%;
						}
					}

					.stat {
						@include align-items(center);
						@include font-size(3.6);
						@include justify-content(center);
						background: rgba(black, 0.5);
						color: $white;
						display: none;
						height: 100%;
						letter-spacing: -0.5px;
						line-height: 140%;
						position: absolute;
						top: 0;
						width: 100%;
					}
				}

				.poll-text {
					width: $col7;

					@media only screen and (max-width: $sml-mobile) {
						padding: 20px;
						width: auto;
					}
				}

				.count {
					background-color: $faint-grey;
					display: none;
					height: 20px;
					margin-top: 10px;
					position: relative;

					@media only screen and (max-width: $mobile) {
						max-width: 250px;
					}

					span {
						position: absolute;
						right: -45px;
						top: -1px;
					}
				}

				&:before {
					margin: 0 15px;

					@media only screen and (max-width: $sml-mobile) {
						display: block;
						margin: 10px 0;
						text-align: center;
					}
				}
			}

			&.selected,
			&.tallied {
				li {
					&:before {
						display: none;
					}

					&:hover {
						box-shadow: none;
						cursor: auto;
					}

					&.selected {
						&:hover {
							box-shadow: 2px 2px 0px 1px #8da27a;
						}

						.count {
							background-color: #a9c78c;
						}
					}
				}
			}

			&.tallied {
				li {
					.poll-img {
						.stat {
							display: flex;
						}
					}

					.count {
						display: block;
					}
				}
			}
		}

		&.no-photo {
			li {
				@include align-items(center);
				padding: 0;
				position: relative;

				&:before {
					padding: 0 0px 0 20px;
					text-align: center;
					width: 63px;
				}
			}

			.poll-text {
				border-left: 2px solid #979797;
				padding: $col-margin-quiz-poll;
				width: $col10;
			}

			&.selected {
				li {
					&:before {
						display: none;
					}

					&:hover {
						box-shadow: none;
						cursor: auto;
					}

					&.selected {
						.poll-text {
							border-left-color: #a9c78c;
						}

						&:hover {
							box-shadow: 2px 2px 0px 1px #8da27a;
						}

						.poll-stat {
							color: #a9c78c;
						}
					}

					.poll-stat {
						@include font-size(2.2);
						font-weight: 700;
						line-height: 34px;
						position: static;
						text-align: center;
						width: 100px;
					}
				}
			}
		}
	}

	.answer {
		display: none;
		margin: 50px;

		@media only screen and (max-width: $mobile) {
			margin: 50px 0;
		}

		p {
			margin: 0;
		}

		h3 {
			@include font-size(2.4);
			display: none;
			font-weight: 700;
			letter-spacing: -0.5px;
			line-height: 30px;
			margin: 0 0 10px;

			&:before {
				font-family: $icon;
				margin-right: 5px;
			}

			span {
				display: none;
			}
		}

		&.correct {
			h3 {
				display: flex;

				&:before {
					color: #a9c78c;
					content: "\e920";
				}

				.correct {
					display: block;
					position: relative;
					left: 10px;
				}
			}
		}

		&.incorrect {
			h3 {
				display: flex;

				&:before {
					color: $red;
					content: "\e90b";
				}

				.incorrect {
					display: block;
					position: relative;
					left: 10px;
				}
			}
		}
	}

	.results {
		border: 2px solid #979797;
		display: none;
		padding: 30px;

		@media only screen and (max-width: $mobile) {
			margin: 0 -13px;
			padding: 15px;
		}

		h2,
		h3,
		p {
			margin: 0;
		}

		.title {
			@include font-size(1.2);
			line-height: 20px;
			margin: 0;
			text-transform: uppercase;
		}

		h2 {
			@include font-size(3.6);
			letter-spacing: -0.5px;
			line-height: 140%;
			font-weight: 400;
		}

		.result {
			@include justify-content(flex-start);
			display: flex;
			margin-top: 25px;

			@media only screen and (max-width: $mobile) {
				@include flex-direction(column);
			}
		}

		.r-img {
			width: $col6;

			@media only screen and (max-width: $mobile) {
				order: 1;
				margin-bottom: 50px;
				width: $col12;
			}

			img {
				width: 100%;
			}
		}

		.blurb {
			line-height: 1.4;
			margin-right: $col-margin;
			width: $col6;

			@media only screen and (max-width: $mobile) {
				order: 2;
				width: $col12;
			}

			p {
				@include font-size(1.8);
				color: $grey;
				font-family: $secondary;
				margin: 20px 0;
			}
		}

		h3 {
			@include font-size(2.4);
			color: $grey;
			font-family: $secondary;
			font-weight: 700;
			letter-spacing: -0.5px;
			line-height: 30px;
		}

		h3.quizLevel {
			@include font-size(1.8);
			font-weight: normal;
		}

		ul {
			margin: 15px 0;
			padding: 0;
		}

		li {
			display: inline;
			margin: 0 10px 0 0;
			text-indent: 0;
		}

		.facebook {
			@include fir("img/facebook.svg", 33px, 33px);
		}

		.twitter {
			@include fir("img/twitter.svg", 33px, 33px);
		}

		a {
			border: 1px solid $lime-green;
			border-radius: 50px;
			vertical-align: top;

			&:hover {
				border: 1px solid $lime-green;
			}
		}
	}
}

.video-wrapper {
	& + .picture-list {
		margin-top: 20px;
	}
}
