.mobile-carryout-cta {
	height: 550px;
	padding: 20px;
	position: relative;
	margin-bottom: 40px;

	&.bottom-align {
		bottom: 40px;
		height: auto;
		left: 0;
		margin: 0;
		position: absolute;
		right: 0;

		.content .cta-container {
			p {
				margin: 24px 0 0;
			}
		}
	}

	@include break-min($large1) {
		display: none;
	}

	.content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		position: relative;
		z-index: 1000;

		h2 {
			@include font-size(2.4);
			color: $red;
			font-weight: $bold-weight;
			line-height: lh(32, 24);
			margin: 14px 0 0;
			text-align: center;
		}

		.cta-container {
			text-align: center;

			p {
				@include font-size(1.8);
				color: $rolling-stone;
				font-weight: $regular-weight;
				letter-spacing: -0.5px;
				line-height: lh(28, 18);
				margin: 12px 0 0;
				text-align: center;
			}
		}
	}

	.bckgrd-img {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 100;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
	}
}
