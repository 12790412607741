// sass-lint:disable max-file-line-count, no-ids, no-qualifying-elements

#stories {
	background-color: $black-bronze;
	height: calc(100vh);
	max-height: 0;
	overflow: hidden;
	position: relative;
	transition: max-height 0.5s ease;

	.stories-toggle {
		@include font-size(1.6);
		background-color: $black-bronze;
		bottom: 0;
		color: $white;
		font-family: $primary;
		left: 0;
		line-height: 28px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 5000;

		&:hover {
			cursor: pointer;
		}

		.toggle-text {
			padding-top: 15px;
			text-align: center;

			&.bounce {
				animation: bounce 1s;
			}
		}

		.closed,
		.opened {
			display: none;
		}
	}

	&.shown {
		max-height: calc(100vh);
		padding-bottom: 100px;
		transition: max-height 0.5s ease;

		.stories-toggle {
			background-color: transparent;
			bottom: initial;
			height: 30px;
			top: 0;
		}

		.closed {
			display: none;
		}

		.opened {
			display: inline;
		}
	}

	&.teaser {
		max-height: 50px;
		transition: max-height 0.5s ease;
		width: 100%;

		&:hover {
			@media only screen and (min-width: $lrg-tablet) {
				cursor: pointer;
				max-height: 70px;
				transition: max-height 0.5s ease;
			}
		}

		.closed {
			display: inline;
		}

		.opened {
			display: none;
		}
	}

	.slick-next,
	.slick-prev {
		&:before {
			@include font-size(1.8);
			font-family: $icon;
			text-shadow: 1px 1px 1px #000;
		}
	}

	.slick-next {
		right: 15px;

		&:before {
			content: "\e90e";
		}
	}

	.slick-prev {
		left: 10px;
		z-index: 1000;

		&:before {
			content: "\e909";
		}
	}

	.slick-slide {
		position: relative;

		&:before {
			@include scrim($scrim2);
		}
	}

	.centered {
		text-align: left;

		@media only screen and (max-width: $mobile) {
			text-align: center;
		}
	}

	.content {
		@include font-size(1.6);
		color: $white;
		font-family: $secondary;
		line-height: 24px;
		position: absolute;
		z-index: 1000;

		@media only screen and (max-width: $mobile) {
			@include align-items(flex-end);
			display: flex;
			height: 85%;
		}

		&.dark {
			@include font-size(1.6);
			left: 40px;
			line-height: 28px;

			a {
				color: $grey;
			}
		}
	}

	.btm-align {
		.alignment {
			@media only screen and (min-width: $lrg-tablet) {
				left: 20px;
				bottom: 70px;
				position: absolute;
			}
		}
	}

	.ctr-align {
		.alignment {
			left: 20px;
			bottom: 0px;
			margin: 5% auto;
			position: absolute;
			width: $col8;

			@media only screen and (max-width: $sml-desktop) {
				width: $col10;
			}

			@media only screen and (max-width: $lrg-tablet) {
				margin: 0 auto;
				width: $col12;
			}

			@media only screen and (max-width: $mobile) {
				left: 0;
			}
		}
	}

	.alignment {
		@media only screen and (max-width: $mobile) {
			padding-bottom: 100px;
		}

		@media only screen and (max-width: $sml-mobile) {
			padding: 0;
		}
	}

	.food {
		.content {
			@include font-size(1.6);
			bottom: 20%;
			left: 20px;
			line-height: 28px;

			a {
				color: $grey;
			}
		}
	}

	.flex {
		@include flex-direction(column);
		@include align-items(stretch);
		position: static;
		width: $col7;
	}

	img {
		height: calc(100vh);
		max-width: 100%;
		width: 100%;
	}

	.scroller {
		text-align: center;

		a {
			color: $white;
			text-shadow: 1px 1px 11px black;

			&:hover {
				border-color: $white;
			}
		}
	}

	.stories {
		bottom: 0;
		padding-bottom: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 4000;

		@media only screen and (min-width: $lrg-tablet) {
			@include align-items(stretch);
			display: flex;
		}

		&.hide-desktop {
			@media only screen and (min-width: $lrg-tablet) {
				display: none;
			}
		}
	}

	.pick {
		@include font-size(1);
		font-family: $secondary;
		letter-spacing: 2px;
		line-height: 20px;
		margin-bottom: 5px;
		text-transform: uppercase;

		.iphone & {
			@include font-size(0.75);
		}
	}

	.title {
		@include font-size(3.6);
		font-family: $secondary;
		font-weight: 700;
		letter-spacing: -0.5px;
		line-height: 1;
		margin: 0 0 30px 0;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 20px;
		}

		.hide-in-mobile {
			@media only screen and (max-width: $mobile) {
				display: none;
			}
		}

		.hide-in-desktop {
			@media only screen and (min-width: $lrg-tablet) {
				display: none;
			}

			@media only screen and (max-width: $mobile) {
				color: $white;
			}
		}
	}

	.lead {
		.title {
			@include font-size(10);
			letter-spacing: -3px;
			line-height: 1;

			@media only screen and (max-width: $mobile) {
				@include font-size(5);
			}
		}
	}

	@media only screen and (min-width: $lrg-tablet) {
		.hover {
			height: 0;
			opacity: 0;
			visibility: hidden;
		}

		.initial {
			height: auto;
			opacity: 1;
			visibility: visible;
			transition: opacity 0.5s ease-in;
		}
	}

	@media only screen and (max-width: $mobile) {
		.initial {
			display: none;
		}

		.hover {
			padding: 0 50px 40px;
			text-align: center;
		}
	}

	.mini-story {
		.content {
			line-height: 1.5;
			height: 100%;

			@media only screen and (max-width: $mobile) {
				display: block;
				height: 75%;
				width: 100%;
			}
		}
	}

	.story1,
	.story2,
	.story3 {
		background-size: cover;
		position: relative;
		transition: all 0.9s ease-in-out;
		width: $col12;

		&:before {
			@include scrim($scrim3);
			opacity: 0.5;
			transition: opacity 0.5s ease;
		}

		&:hover {
			&:before {
				opacity: 1;
				transition: opacity 0.5s ease;
			}
		}

		.content {
			line-height: 1.5;
			padding: 0 30px;
			width: $col10;

			@media only screen and (max-width: $lrg-tablet) {
				padding: 0 25px;
			}
		}

		@media only screen and (min-width: $lrg-tablet) {
			&:hover {
				&.ctr-align {
					.alignment {
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}

				&.btm-align {
					.alignment {
						left: 80px;
						bottom: 100px;
					}
				}

				.hover {
					height: auto;
					opacity: 1;
					visibility: visible;
					transition: opacity 0.5s ease-in;
				}

				.initial {
					height: 0;
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}

	&.one {
		img {
			display: block;
		}

		.content {
			bottom: 80px;
		}
	}

	&.two {
		.story1,
		.story2 {
			height: 100%;
			width: $col6;

			.content {
				bottom: 80px;
				width: $col8;
			}
		}

		.stories {
			display: flex;

			& > div {
				@include flex-grow(1);

				picture {
					display: block;
				}
			}
		}

		.story2 {
			height: 100%;
		}
	}

	&.three {
		.story1 {
			width: $col7;

			.content {
				bottom: 30px;
				width: $col8;

				.initial {
					.title {
						margin-bottom: 10px;
					}
				}
			}
		}

		.story2,
		.story3 {
			@include flex-grow(1);
			width: $col12;

			.title {
				@include font-size(3.6);
			}

			.content {
				bottom: 40px;
			}
		}

		.story3 {
			.content {
				bottom: 40px;

				.initial {
					.title {
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	.btn {
		&:hover {
			color: $white;
		}
	}

	.story-focus {
		&:before {
			opacity: 1;
			transition: opacity 0.5s ease;
		}
	}
}

.story {
	overflow: hidden;
	position: relative;

	article {
		padding-top: 20px;
		position: relative;

		p,
		ul,
		ol {
			margin: 0 100px 32px 102px;

			@media only screen and (max-width: $mobile) {
				margin: 0 0 30px;
				padding-left: 10px;
				padding-right: 10px;
			}

			&.blurb {
				margin: 0 0 30px;
			}

			&.tag {
				margin: 0 0 10px 0;
			}
		}

		.content {
			@include font-size(1.8);
			font-family: $secondary;
			letter-spacing: 0px;
			line-height: 32px;
			margin: 0 auto;
			max-width: 962px;
			width: $col9;

			@media only screen and (max-width: $lrg-tablet) {
				width: $col10;
			}

			@media only screen and (max-width: $mobile) {
				margin: 0;
				max-width: none;
				width: auto;
			}

			.initial {
				&:first-letter {
					@media screen {
						@media only screen and (min-width: $lrg-tablet) {
							@include font-size(11);
							color: $red;
							float: left;
							font-family: $primary;
							font-weight: 800;
							letter-spacing: 0px;
							line-height: 90px;
							margin-right: 5px;
						}

						.food & {
							color: $blue;
						}

						.news & {
							color: $purple;
						}

						.lifestyle & {
							color: $lime-green;
						}

						.cfa-stories & {
							color: $orange;
						}
					}
				}
			}
		}

		.story-start {
			padding-left: 10px;
			padding-right: 10px;
		}

		.tag {
			a {
				@include font-size(1.2);
				font-family: $secondary;
				font-weight: 600;
				letter-spacing: 2.4px;
				line-height: 20px;
				text-transform: uppercase;
			}

			.food & {
				a {
					color: $blue;

					&:hover {
						border-color: $blue;
					}
				}
			}

			.news & {
				a {
					color: $purple;

					&:hover {
						border-color: $purple;
					}
				}
			}

			.cfa-stories & {
				a {
					color: $orange;

					&:hover {
						border-color: $orange;
					}
				}
			}

			.lifestyle & {
				a {
					color: $lime-green;

					&:hover {
						border-color: $lime-green;
					}
				}
			}
		}

		.blurb {
			@include font-size(3.6);
			font-family: $primary;
			letter-spacing: -0.5px;
			line-height: 48px;

			&.sml {
				@include font-size(2.2);
				line-height: 34px;
				margin-bottom: 50px;

				.initial {
					line-height: 70px;
				}
			}

			.food & {
				color: $blue;
			}

			.news & {
				color: $purple;
			}

			.lifestyle & {
				color: $lime-green;
			}

			.cfa-stories & {
				color: $orange;
			}
		}

		blockquote {
			@include font-size(3.6);
			font-family: $primary;
			letter-spacing: -0.5px;
			line-height: 140%;
			margin: 0;

			p {
				margin: 0 0 30px 0;
			}

			.food & {
				color: $blue;
			}

			.news & {
				color: $purple;
			}

			.lifestyle & {
				color: $lime-green;
			}

			.cfa-stories & {
				color: $orange;
			}

			@media only screen and (max-width: $mobile) {
				@include font-size(2.8);
				letter-spacing: -0.5px;
				line-height: 36px;
				margin: 40px 0;
			}

			&.left,
			&.right {
				float: left;
				margin: 0 $col-margin $col-margin 0;
				width: $col6;

				@media only screen and (max-width: $mobile) {
					float: none;
					width: auto;
				}
			}

			&.right {
				float: right;
				margin: 0 0 $col-margin $col-margin;
			}

			&.tile,
			&.red-dots,
			&.glass,
			&.brown,
			&.wood-flower {
				@include font-size(3.6);
				letter-spacing: -0.5px;
				line-height: 140%;
				margin: 0 0 50px 0;
				padding: 100px 125px 50px;

				@media only screen and (max-width: $lrg-tablet) {
					@include font-size(2.8);
					line-height: 150%;
					margin: 0 -20px 50px;
					padding: 50px 20px 20px 20px;
				}
			}

			&.tile {
				// sass-lint:disable-block max-line-length, indentation
				background: url("img/tile-co.jpg") transparent top center repeat;
				color: $grey;
				text-shadow: 0px 2px 4px white, 0px 2px 4px white, 0px 2px 4px white,
					0px 2px 4px white, 0px 2px 4px white;

				.source {
					color: $red;
				}
			}

			&.red-dots {
				background: url("img/reddots.jpg") transparent top center repeat;
				color: $white;

				.source {
					color: $white;
				}
			}

			&.glass {
				background: url("img/glass.jpg") transparent top center no-repeat;
				background-size: cover;
				color: $white;

				.source {
					color: $white;
				}
			}

			&.brown {
				// sass-lint:disable variable-for-property
				background-color: #9d5e2b;
				// sass-lint:enable variable-for-property
				color: $white;

				.source {
					color: $white;
				}
			}

			&.wood-flower {
				background: url("img/woodflower.jpg") transparent top center no-repeat;
				background-size: cover;
				color: $blue;
				text-shadow: 0px 2px 4px white;

				.source {
					// sass-lint:disable variable-for-property
					color: #5f5951;
					// sass-lint:enable variable-for-property
				}
			}

			.source {
				@include font-size(2.8);
				display: block;
				font-family: $secondary;
				letter-spacing: 0;
				line-height: 150%;
				margin: 20px 0;

				@media only screen and (max-width: $lrg-tablet) {
					@include font-size(2.2);
					line-height: 34px;
				}
			}
		}

		.tags {
			@include font-size(1.2);
			clear: both;
			letter-spacing: 2.4px;
			line-height: 20px;
			text-transform: uppercase;
			padding-left: 10px;
			padding-right: 10px;

			span {
				margin-right: 30px;

				@media only screen and (max-width: $mobile) {
					display: block;
					margin-bottom: 10px;
					margin-right: 0;
				}
			}

			a {
				@include font-size(1.6);
				font-weight: 200;
				line-height: 28px;
				margin: 0 35px 0 0;

				@media only screen and (max-width: $mobile) {
					margin: 0 30px 0 0;
				}

				.food & {
					color: $blue;

					&:hover {
						border-color: $blue;
					}
				}

				.news & {
					color: $purple;
				}

				.lifestyle & {
					color: $lime-green;
				}

				.cfa-stories & {
					color: $orange;
				}
			}
		}
	}

	&.wrapper {
		@media only screen and (max-width: $mobile) {
			padding: 0 0;
		}
	}
}

.story-heading {
	@media only screen and (max-width: $mobile) {
		padding: 0 0;
	}
}

.story-landing {
	.story:first-child:nth-last-child(2),
	.story:first-child:nth-last-child(2) ~ .story {
		& > a {
			height: 300px;

			@media only screen and (max-width: $mobile) {
				height: 250px;
				margin-bottom: 35px;
				overflow: hidden;
			}
		}

		&.top-shared {
			h3 {
				@include font-size(5);
				font-weight: 600;
			}
		}

		h3 {
			@include font-size(3.6);
			line-height: 44px;
			font-weight: 700;

			& + p {
				overflow: hidden;
				line-height: 1.5;
				max-height: 200px;

				&:after {
					content: "...";
					color: $grey;
				}
			}
		}
	}

	.load-more {
		margin: 15px auto;
	}
}

.story-top {
	margin-bottom: 20px;
	overflow: hidden;

	.flex {
		@include flex-wrap(wrap);

		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}

	.social {
		display: none;
		position: absolute;
		right: 20px;

		&.slideout {
			animation: slideOutRight 1s;
		}

		@media only screen and (max-width: $mobile) {
			position: relative;
		}

		ul {
			display: inline-block;

			&.alt {
				display: none;
			}
		}

		a {
			color: $white;

			&:hover {
				&.icon-facebook-up {
					&:before {
						content: "\e901";
					}
				}

				&.icon-twitter-up {
					&:before {
						content: "\e912";
					}
				}
			}
		}
	}

	@media only screen {
		&.locked {
			animation: slideInDown 0.5s;
			background-color: $red;
			color: $white;
			left: 0;
			margin-bottom: 0;
			position: fixed;
			padding: 4px 0;
			top: 61px;
			width: 100%;
			z-index: 1000;

			.food & {
				background-color: $blue;
			}

			.news & {
				background-color: $purple;
			}

			.lifestyle & {
				background-color: $lime-green;
			}

			.cfa-stories & {
				background-color: $orange;
			}

			.recipe-specifics {
				display: none;
			}

			@media only screen and (max-width: $mobile) {
				top: 52px;
				padding: 5px 0 0;
			}

			h1 {
				@include font-size(1.6);
				color: $white;
				display: inline-block;
				font-family: $primary;
				font-weight: 400;
				line-height: 28px;
				margin: 0;
				width: $col6;

				@media only screen and (max-width: $mobile) {
					display: none;
				}
			}

			.article-details {
				@include align-items(center);
				@include justify-content(space-between);
				display: flex;
				max-width: $bp1;
				margin: 0 auto;
				overflow: hidden;
				width: 100%;

				.recipe & {
					.print {
						display: block;
						font-weight: 600;
						margin-top: 10px;
						width: $col2;

						&:hover {
							cursor: pointer;
						}

						@media only screen and (max-width: $lrg-tablet) {
							@include font-size(1.3);
							margin-top: 5px;
						}

						@media only screen and (max-width: $mobile) {
							display: none;
						}
					}
				}

				h1 {
					margin-top: 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: $col5;

					@media only screen and (max-width: $lrg-tablet) {
						width: $col4;
					}
				}

				@media only screen and (max-width: $mobile) {
					position: relative;
				}
			}

			.cs,
			.byline {
				display: none;
			}

			.social {
				@include align-items(center);
				@include font-size(1.2);
				@include justify-content(flex-end);
				display: flex;
				font-family: $secondary;
				line-height: 20px;
				margin-top: 10px;
				position: static;
				text-transform: uppercase;
				width: $col4;

				@media only screen and (max-width: $lrg-tablet) {
					width: $col5;
				}

				@media only screen and (max-width: $mobile) {
					height: 50px;
					width: 100%;

					.social-icons {
						padding: 0;
					}
				}

				& > span {
					@include font-size(1);
					display: block;
					letter-spacing: 2px;
					line-height: 20px;
					margin: 0 20px 0 0;
				}

				ul {
					margin: 0;
				}
			}
		}
	}

	h1 {
		@include font-size(3.6);
		color: $dkgrey;
		font-family: $secondary;
		font-weight: 700;
		letter-spacing: -0.5px;
		line-height: 140%;
		margin: 0 0 10px 0;
	}

	.byline {
		@include font-size(1.2);
		font-family: $secondary;
		font-weight: 600;
		letter-spacing: 3px;
		line-height: 20px;
		margin: 0;
		text-transform: uppercase;
	}

	.author {
		font-weight: 600;
		margin-right: 20px;

		.food & {
			color: $blue;

			&:hover {
				border-color: $blue;
			}
		}

		.news & {
			color: $purple;

			&:hover {
				border-color: $purple;
			}
		}

		.lifestyle & {
			color: $lime-green;

			&:hover {
				border-color: $lime-green;
			}
		}

		.cfa-stories & {
			color: $orange;

			&:hover {
				border-color: $orange;
			}
		}

		@media only screen and (max-width: $mobile) {
			display: block;
			margin: 10px 0;
		}
	}
}

.article-details {
	width: $col9;

	.print {
		display: none;
	}

	@media only screen and (max-width: $mobile) {
		width: auto;
		padding-left: 10px;
	}
}

.related {
	margin: 100px auto 0;
	text-align: center;

	.home-stories & {
		margin-top: 0;
	}

	@media only screen and (max-width: $mobile) {
		margin-top: 50px;

		h2 {
			margin-bottom: 50px;
		}
	}

	.story {
		@include flex-grow(1);
		@include font-size(1.6);
		border: 2px solid #ededed;
		border-right-color: transparent;
		font-family: $secondary;
		letter-spacing: 0px;
		line-height: 28px;
		padding: 30px 30px 60px 30px;
		position: relative;
		text-align: left;
		transition: border 0.5s ease;
		width: $col12;

		h3 {
			font-weight: 700;

			& + p {
				overflow: hidden;
				line-height: 1.5;
				max-height: 100px;

				&:after {
					content: "...";
					color: $grey;
				}
			}
		}

		& > a,
		& > span.story-img {
			border: 2px solid white;
			display: block;

			@media only screen and (min-width: $lrg-tablet) {
				height: 200px;
			}

			@media only screen and (max-width: $mobile) {
				min-height: 200px;
			}
		}

		&:hover {
			cursor: pointer;
		}

		&.top-shared {
			padding-bottom: 63px;

			@media only screen and (max-width: $mobile) {
				padding: 0;

				.top-shared-frame {
					height: 100%;
					position: static;
					width: 100%;

					h3 {
						margin-top: 0;
						padding-top: 35px;
					}
				}
			}
		}

		@media only screen and (max-width: $mobile) {
			padding: 30px 15px 60px;
		}

		&.full {
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 400px;

			@media only screen and (max-width: $mobile) {
				border: 0;
				min-height: 500px;
			}

			&:hover {
				.lead {
					@include background($scrim2);
					transition: all 2s ease;
				}
			}

			& > a {
				border: 0;
			}

			p {
				&:after {
					display: none;
				}
			}

			.lead {
				@include flex-direction(column);
				@include align-items(flex-start);
				@include justify-content(flex-end);
				@include background($scrim);
				bottom: 0;
				color: $white;
				display: flex;
				height: 100%;
				left: 0;
				right: 0;
				position: absolute;
				padding: 0 20px;
				top: 0;
			}

			h3 {
				@include font-size(2.7);
				font-family: $primary;
				font-weight: 500;
				padding: 0 10px 0 0;
				width: 100%;

				a {
					color: $white;
					font-weight: 600;
				}
			}

			a {
				color: $white;
				font-weight: 700;
			}

			.tag {
				color: $white;
				position: relative;

				span {
					color: $white;
				}
			}

			&.social {
				h3 {
					@include font-size(2.2);
					font-weight: 300;
					line-height: 34px;
				}
			}
		}

		&.food {
			.tag {
				a {
					color: $blue;

					&:hover {
						border-color: $blue;
					}
				}
			}
		}

		&.news {
			.tag {
				a {
					color: $purple;

					&:hover {
						border-color: $purple;
					}
				}
			}
		}

		&.lifestyle {
			.tag {
				a {
					color: $lime-green;

					&:hover {
						border-color: $lime-green;
					}
				}
			}
		}

		&.cfa-stories {
			.tag {
				a {
					color: $orange;

					&:hover {
						border-color: $orange;
					}
				}
			}
		}

		@media only screen and (max-width: $mobile) {
			border-bottom: 0;
			border-left: 0;
			border-right: 0;
			width: auto;
		}

		@media only screen and (min-width: $lrg-tablet) {
			&:hover {
				&.lifestyle {
					border-color: $lime-green;

					& > a {
						border-color: $lime-green;
					}

					h3 {
						a {
							color: $lime-green;
						}
					}
				}

				&.cfa-stories {
					border-color: $orange;

					& > a {
						border-color: $orange;
					}

					h3 {
						a {
							color: $orange;
						}
					}
				}

				&.news {
					border-color: $purple;

					& > a {
						border-color: $purple;
					}

					h3 {
						a {
							color: $purple;
						}
					}
				}

				&.food {
					border-color: $blue;

					& > a {
						border-color: $blue;
					}

					h3 {
						a {
							color: $blue;
						}
					}
				}
			}
		}

		&:first-child {
			border-right: 2px solid transparent;
		}

		&:last-child {
			border-right-color: #ededed;
		}

		img {
			width: 100%;
		}

		p {
			margin: 0 0 20px 0;
		}
	}

	.story-landing & {
		margin: 0 auto -1px;

		.tag {
			font-weight: 600;

			.featured {
				color: $grey;
				font-weight: 400;
			}
		}

		.flex {
			@media only screen and (max-width: $mobile) {
				@include flex-direction(column);
				display: flex;
			}
		}
	}

	@media only screen and (max-width: $mobile) {
		padding: 0;

		.flex {
			border-bottom: 2px solid #ededed;
			display: block;
		}
	}

	h2 {
		@include font-size(3.6);
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 140%;
	}

	.btn {
		margin: 50px 0;
	}

	h3 {
		@include font-size(2.2);
		font-weight: 600;
		line-height: 34px;
		margin: 15px 0 10px;

		a {
			color: $grey;

			&:hover {
				border-color: transparent;
			}
		}
	}

	.tag {
		@include font-size(1.2);
		bottom: 0;
		letter-spacing: 2.4px;
		line-height: 20px;
		position: absolute;
		text-transform: uppercase;
	}
}

figure {
	.story-article & {
		margin: 70px 0;

		figcaption {
			margin: 20px 0 0;

			@media only screen and (max-width: $mobile) {
				width: $col11;
			}
		}

		@media only screen and (max-width: $mobile) {
			margin: 70px 0 70px -10px;
		}
	}

	&.left {
		padding-right: 20px;

		& + .recipe {
			dt {
				text-indent: -40px;

				@media only screen and (max-width: $mobile) {
					text-indent: 0;
				}
			}
		}

		&.has-photographer {
			.photographer {
				right: 10%;
			}
		}
	}

	img {
		width: 100%;
	}
}

.yt-video {
	cursor: pointer;
	position: relative;

	.youtube-video-overlay {
		background: url("img/youtube.png") center center no-repeat;
		background-size: contain;
		display: block;
		height: 100px;
		position: absolute;
		width: 100px;

		@media only screen and (max-width: $mobile) {
			position: absolute;
			width: 100px;
			height: 100px;
		}
	}

	.story-img {
		align-items: center;
		justify-content: center;
		position: relative;

		@media only screen and (min-width: $lrg-tablet) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.stories-feature,
a.youtube-video {
	.youtube-video-overlay {
		margin-top: -35px;
		margin-left: -35px;
		width: 70px;
		height: 70px;
	}
}

.recipe {
	dl {
		@include font-size(1.8);
		margin: 0 100px 40px 50px;

		@media only screen and (max-width: $mobile) {
			margin: 0 0 40px 0;
		}
	}

	dt {
		@include font-size(2.4);
		font-weight: 700;
		letter-spacing: -0.5px;
		line-height: 30px;
		margin-bottom: 10px;
	}

	dd {
		margin: 0;
		padding: 0;

		p {
			margin: 20px 0;
		}

		ul,
		ol {
			margin: 10px 0 10px 15px;
			padding: 0;
		}

		ul {
			list-style: none;

			li {
				margin: 0 0 10px 33px;
				text-indent: -30px;

				&:before {
					color: $red;
					content: "◦";
					margin-right: 20px;
				}
			}
		}

		li {
			line-height: 1.5;
			margin: 0 0 20px 0;
		}

		ol {
			li {
				margin-left: 20px;
				padding-left: 20px;
			}
		}
	}

	figure {
		&.left {
			padding-right: 30px;

			.photographer {
				right: 50px;
			}

			& + dl {
				dt {
					text-indent: -40px;

					@media only screen and (max-width: $mobile) {
						text-indent: 0;
					}
				}
			}
		}
	}
}

.lead-story {
	@include font-size(4);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	position: relative;

	@media only screen and (max-width: $mobile) {
		padding-top: 21px;
	}

	.title {
		max-width: 540px;
		width: $col12;

		@media only screen and (max-width: $mobile) {
			width: auto;
		}

		a {
			border: 0;
			color: $white;
			text-shadow: 2px 2px 6px black;
			transition: text-shadow 0.75s ease;

			&:hover {
				text-shadow: 2px 2px 11px black;
				transition: text-shadow 0.75s ease;
			}
		}
	}

	.tag {
		@include font-size(1.2);
		font-family: $secondary;
		font-weight: 600;
		letter-spacing: 3px;
		line-height: 20px;
		text-transform: uppercase;

		span {
			font-weight: 400;
		}
	}

	.slide-content {
		@include align-items(flex-end);
		@include justify-content(flex-start);
		@include background($scrim);
		bottom: 0;
		display: flex;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: $col12;

		@media only screen and (max-width: $mobile) {
			position: static;
		}

		.wrapper {
			margin: 0;

			@media only screen and (max-width: $mobile) {
				padding-bottom: 35px;
			}
		}

		a {
			color: $white;
		}
	}

	img {
		width: 100%;
	}

	.wire-logo {
		// sass-lint:disable-block no-duplicate-properties
		position: absolute;
		top: 30px;
		left: 30px;
		z-index: 999;
		cursor: hand;
		cursor: pointer;
		width: 200px;
		transition: all 0.5s ease;

		@media only screen and (max-width: $mobile) {
			bottom: 20px;
			left: initial;
			right: 20px;
			top: initial;
			width: 100px;
		}

		&.wire-logo-shadow {
			filter: drop-shadow(2px 2px 2px #d2d4d4);
			transition: all 0.5s ease;
		}
	}

	.wire-title {
		@include font-size(2);
		color: $grey;
		left: 50px;
		position: absolute;
		top: 210px;
		z-index: 999;
	}

	.hover-content {
		position: absolute;
		background-color: $white;
		width: 100%;
		height: 100%;
		opacity: 0.9;
	}

	.hover-inner {
		position: absolute;
		z-index: 999;
		color: $grey;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 620px;
	}

	.title-small {
		@include font-size(2);
		position: relative;
		text-align: center;
	}

	.title-large {
		@include font-size(4);
		position: relative;
		text-align: center;
	}
}

.frame {
	& > .story-carousel-frame {
		padding-top: 0px;

		@media only screen and (max-width: $mobile) {
			padding-top: 0;
		}
	}
}

.story-carousel {
	&.slick-dotted {
		&.slick-slider {
			margin-bottom: 0;
		}
	}

	.slick-dots {
		@media only screen and (max-width: $mobile) {
			bottom: 20px;
		}
	}
}

.top-shared {
	overflow: hidden;
	position: relative;
	width: 100%;

	.top-shared-frame {
		background: url("img/shared-bkgd.png") transparent top left no-repeat;
		background-size: cover;
		height: 100%;
		padding: 20px 0 10px 0;
		position: absolute;
		width: 90%;

		@media only screen and (min-width: $lrg-tablet) {
			position: relative;
			width: 100%;
		}

		h3 {
			@include font-size(5);
			color: $red;
			font-family: $primary;
			font-weight: 600;
			margin: 35px 0 10px 0;
			text-align: center;
		}

		.blurb {
			@include font-size(1.4);
			color: $grey;
			font-family: $primary;
			text-align: center;
			margin-bottom: 35px;
		}
	}

	ul {
		list-style: none;
		margin: 0 20px;
		padding: 0;
	}

	li {
		margin: 45px 0 0 0;

		@media only screen and (max-width: $mobile) {
			margin: 23px 0;
		}

		a {
			@include align-items(center);
			border: 0;
			display: flex;

			span {
				@include align-self(center);
			}
		}
	}

	.number {
		@include font-size(2.2);
		background-color: $white;
		border: 2px solid $red;
		border-radius: 25px;
		display: inline-block;
		font-family: $primary;
		margin-right: 25px;
		padding: 7px 15px 6px;
		vertical-align: super;
	}

	a {
		color: $grey;
	}

	.lifestyle {
		.number {
			border-color: $lime-green;
			color: $lime-green;
			transition: all 0.5s ease;
		}

		&:hover {
			.number {
				background-color: $lime-green;
				color: $white;
			}

			a {
				color: $lime-green;
			}
		}
	}

	.food {
		.number {
			border-color: $blue;
			color: $blue;
			transition: all 0.5s ease;
		}

		&:hover {
			.number {
				background-color: $blue;
				color: $white;
			}

			a {
				color: $blue;
			}
		}
	}

	.cfa-stories {
		.number {
			border-color: $orange;
			color: $orange;
			transition: all 0.5s ease;
		}

		&:hover {
			.number {
				background-color: $orange;
				color: $white;
				transition: all 0.5s ease;
			}

			a {
				color: $orange;
			}
		}
	}

	.news {
		.number {
			border-color: $purple;
			color: $purple;
		}

		&:hover {
			.number {
				background-color: $purple;
				color: $white;
			}

			a {
				color: $purple;
			}
		}
	}
}

.story-title {
	@include font-size(2.2);
	font-weight: 700;
	line-height: 26px;
	margin-top: -3px;
}

.category-title {
	@include align-items(center);
	@include justify-content(center);
	display: flex;
	padding: 80px 0 30px;
	width: $col7;

	@media only screen and (max-width: $mobile) {
		display: block;
		padding: 0 0 25px;
		text-align: center;
		width: auto;

		.cs {
			margin-bottom: 25px;
		}
	}
}

.category-tagline {
	@include font-size(1.4);
	border-left: 2px solid #979797;
	font-family: $secondary;
	padding-left: 35px;
	width: $col5;

	@media only screen and (max-width: $mobile) {
		border-left: 0;
		border-top: 2px solid #979797;
		line-height: 1.33;
		margin: 0 auto;
		max-width: 225px;
		padding-left: 0;
		padding-top: 25px;
		width: 60%;

		p {
			margin: 0;
		}
	}

	h1 {
		@include font-size(6.6);
		font-family: $primary;
		font-weight: 600;
		margin: 0;

		@media only screen and (max-width: $mobile) {
			@include font-size(4.2);
			font-weight: 600;
			line-height: 1;
		}

		&.food {
			color: $blue;
		}

		&.lifestyle {
			color: $lime-green;
		}

		&.news {
			color: $purple;
		}

		&.cfa-stories {
			color: $orange;
		}
	}
}

.story-start {
	& + p {
		&:first-letter {
			@include font-size(11);
			color: $red;
			float: left;
			font-family: $primary;
			font-weight: 800;
			letter-spacing: 0px;
			margin-bottom: 10px;
			margin-right: 10px;
			margin-top: 30px;

			.food & {
				color: $blue;
			}

			.news & {
				color: $purple;
			}

			.lifestyle & {
				color: $lime-green;
			}

			.cfa-stories & {
				color: $orange;
			}
		}
	}
}
// #story-tag-feed-id {
// 	.category-title {
// 		padding: 0;
// 		h1 {
// 			@include visuallyhidden();
// 		}
// 	}
// 	&.lead-story {
// 		&+.related {
// 			margin-top: 0;
// 		}
// 	}
// }

.story-image-carousel {
	margin: 0 30px 30px 30px;

	@media only screen and (max-width: $mobile) {
		margin: 0;
	}

	.slick-slide {
		text-align: center;

		.slide-img-container {
			position: relative;
			display: block;
			overflow: hidden;

			.bg {
				// sass-lint:disable-block no-misspelled-properties
				background-repeat: no-repeat;
				background-size: cover;
				filter: blur(30px);
				height: 100%;
				left: 0;
				opacity: 0.7;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1;
			}

			.img__wrap {
				height: 100%;
				margin: 30px;
				max-width: 100%;
				position: relative;
				z-index: 100;

				@media only screen and (max-width: $sml-mobile) {
					margin: 0 auto;
				}

				img {
					@media only screen and (min-width: $sml-mobile) {
						max-height: 318px;
					}

					@media only screen and (max-width: $mobile) {
						max-height: 371px;
					}

					@media only screen and (min-width: $sml-desktop) {
						max-height: 447px;
					}

					display: inline-block;
				}
			}
		}

		img {
			@media only screen and (max-width: $sml-mobile) {
				height: 220px;
			}

			display: inline-block;
			height: auto;
		}
	}

	.page-info {
		font-size: 75%;
	}

	.story-image-carousel-content {
		margin-top: 30px;

		@media only screen and (max-width: $mobile) {
			margin-left: 15px;
			margin-right: 15px;
		}

		.carousel-left {
			width: 10%;

			@media only screen and (max-width: $sml-mobile) {
				margin-right: 10px;
				width: 20%;
			}

			margin-right: 0px;
			float: left;

			.page-info {
				color: $light-grey;
				font-family: "franklin-gothic";
				font-size: 2.3rem;
				font-weight: 700;
				letter-spacing: -0.5px;
				line-height: 36px;
				margin-top: -6px;
			}
		}

		p {
			margin: 0;
		}

		i {
			font-size: 75%;
		}

		.page-info {
			float: left;
			font-size: 2.2rem;
		}

		.caption {
			font-family: "franklin-gothic";
			font-size: 1.2rem;
			color: $light-grey;
			line-height: 20px;
			font-weight: normal;
			padding: 0;
		}

		.credit {
			margin: 5px 0 16px 0;
			font-family: "franklin-gothic";
			font-size: 1.1rem;
			color: $light-grey;
			letter-spacing: 2.5px;
			line-height: 11px;
			text-transform: uppercase;
			font-weight: 700;
		}

		.carousel-right {
			width: calc(90% - 50px);
			float: left;
			text-align: left;

			@media only screen and (max-width: $mobile) {
				line-height: 1.3;
			}
		}
	}

	.slick-dots {
		margin: 0 0 0 0;

		li {
			&:before {
				content: none;
			}
		}
	}

	.slick-prev-next {
		@include font-size(1.8);
		content: "\e902";
		font-family: $icon;
		position: absolute;
		color: $red;
	}

	.slick-next {
		&:before {
			@extend .slick-prev-next;
			right: -15px;
			@include transform(rotate(270deg));

			@media only screen and (max-width: $mobile) {
				color: $white;
			}
		}

		@media only screen and (max-width: $mobile) {
			right: 25px;
		}
	}

	.slick-prev {
		&:before {
			@extend .slick-prev-next;
			left: -15px;
			@include transform(rotate(90deg));

			@media only screen and (max-width: $mobile) {
				color: $white;
			}
		}

		@media only screen and (max-width: $mobile) {
			left: 25px;
			z-index: 1;
		}
	}
}

@media only screen and (min-width: $lrg-tablet) {
	.hover-focus {
		height: auto;
		opacity: 1;
		visibility: visible;
		transition: opacity 0.5s ease-in;

		&:before {
			opacity: 1;
			transition: opacity 0.5s ease;
		}
	}

	.initial-focus {
		height: 0;
		opacity: 0;
		visibility: hidden;
	}
}
