// sass-lint:disable max-file-line-count

// Font size REM to PX conversion. To use:
// @include font-size(1.6);
// For a 16px font size, 2.0, for 20px, etc.
@mixin font-size($font-size: 1.6) {
	// sass-lint:disable-block no-duplicate-properties
	font-size: ($font-size * 10) + px;
	font-size: $font-size + rem;
}

// FIR, image replacement method
// @include fir("filepath", image height in px, image width in px);
// @include fir("samplePath/sampleImage.png", 20px, 20px);
@mixin fir($img, $height, $width) {
	background: url($img) transparent top left no-repeat;
	background-size: $width $height;
	display: inline-block;
	height: $height;
	overflow: hidden;
	text-indent: 120%;
	white-space: nowrap;
	width: $width;
}

@mixin visuallyhidden() {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@mixin unhidden() {
	border: none;
	clip: auto;
	height: auto;
	margin: auto;
	overflow: visible;
	padding: inherit;
	position: static;
	width: auto;
}

@mixin default-btn() {
	@include font-size(1.6);
	background: $btn-grad;
	border: 0;
	border-radius: 24px;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-family: $primary;
	font-weight: 600;
	letter-spacing: -0.5px;
	line-height: 17px;
	padding: 15px 19px;
	text-indent: 0.5px;
	transition: all 0.5s ease-in-out;

	&:hover {
		background: $btn-hover-grad;
		box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
		color: $white;
		transform: translateY(-1px);
		transition: all 0.3s ease-in-out;
	}
}

@mixin background-size-cover {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin hoverlines() {
	border-bottom: 2px transparent dotted;
	color: $red;
	text-decoration: none;
	transition: color 0.5s ease;

	&:hover {
		@media only screen and (min-width: $mobile) {
			color: $dkred;
		}

		transition: color 0.5s ease;

		@media only screen and (max-width: $mobile) {
			border-color: transparent;
		}
	}
}

@mixin item-details() {
	@include font-size(1);
	border: 0;
	color: $blue;
	display: block;
	font-family: $primary;
	letter-spacing: 2px;
	line-height: 20px;
	margin: 0 0 5px 0;
	text-transform: uppercase;
}

@mixin scrim($bkgdvariable) {
	@include background($bkgdvariable);
	content: " ";
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 200;
}

@mixin clearfix {
	clear: both;

	&:before {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin full-width-child {
	position: relative;
	width: 100vw;
	left: calc(-50vw + 50%);
}

@mixin thirdlevel() {
	@include font-size(4.8);
	line-height: 130%;
	letter-spacing: -1px;
}

// ------------------------------------
//   #WS MIXINS
// ------------------------------------

// Breakpoints
$small: 320px;
$medium: 768px;
$large: 1088px;

@mixin breakpoint($point) {
	// for desktop layouts
	@if $point == desktop {
		@media (min-width: $large) {
			@content;
		}
	}
	// tablet
	@if $point == tablet {
		@media (min-width: $medium) {
			@content;
		}
	}
	// layouts < 768px
	@if $point == mobileOnly {
		@media (max-width: $medium - 1) {
			@content;
		}
	}
}

// Usage @include flex()
@mixin flexbox() {
	// sass-lint:disable-block no-duplicate-properties
	display: flex;
}

// Usage @include flex-reverse()
@mixin flex-reverse() {
	flex-direction: row-reverse;
}

// Usage @include flex-reverse()
@mixin flex-reverse() {
	flex-direction: row-reverse;
}

// Usage @include flex-reverse-column()
@mixin flex-reverse-column() {
	flex-direction: column-reverse;
}

@mixin flex-rules() {
	// sass-lint:disable-block no-duplicate-properties
	box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex: 0 0 auto;
	flex-basis: 0;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: wrap;
	position: relative;
}

@mixin rotate($degree) {
	transform: rotate($degree);
}

@mixin column-rules() {
	box-sizing: border-box;
	flex: 0 0 auto;
	position: relative;
}

@mixin animated() {
	&.animated {
		.play {
			background: url("img/pause.png") transparent top left no-repeat;
			background-size: 90%;
			border: 0;
			bottom: 2%;
			height: 30px;
			left: initial;
			right: 2%;
			top: initial;
			transform: translate(-2%, -2%);
			width: 30px;

			&.paused {
				background: url("img/play.png") transparent top left no-repeat;
				background-size: 90%;
			}
		}
	}
}

// ------------------------------------
//     #CP Additions
// ------------------------------------

// SMALL
$small1: 320px; // *
$small2: 400px;
$small3: 480px;
$small4: 640px;
$small5: 690px;

// MEDIUM
$medium1: 748px;
$medium2: 768px; // *
$medium3: 800px;
$medium4: 880px;
$medium5: 928px;

// LARGE
$large1: 960px;
$large2: 1024px; // *
$large3: 1088px;
$large4: 1200px;

// HUGE
$huge1: 1300px;
$huge2: 1440px; // *
$huge3: 91;

// simple break min
@mixin break-min($media) {
	@media screen and (min-width: #{$media}) {
		@content;
	}
}

@mixin break-max($media) {
	@media screen and (max-width: #{$media}) {
		@content;
	}
}

// Get raw value
@function get-value($n) {
	@return $n / ($n * 0 + 1);
}

// Covert lineheight px to unitless
@function lh($line-height, $font-size) {
	@return get-value($line-height / $font-size);
}

// weights
$regular-weight: 500;
$medium-weight: 600;
$bold-weight: 700;

@mixin nge-font() {
	font-family: $nge-font;
	font-weight: normal;
	line-height: 1;
	letter-spacing: 0.085em;
	text-indent: 10px;
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	// sass-lint:enable no-vendor-prefixes
}

@mixin nge-card-title() {
	@include font-size(1.2);
	font-family: $primary;
	font-weight: $medium-weight;
	line-height: lh(20px, 12px);
	// sass-lint:disable no-vendor-prefixes
	-moz-osx-font-smoothing: grayscale;
	// sass-lint:enable no-vendor-prefixes

	@include break-min($small5) {
		@include font-size(1.8);
		font-weight: $regular-weight;
		line-height: lh(32px, 18px);
		letter-spacing: -0.5px;
	}
}

@mixin link-underline() {
	cursor: pointer;
	text-decoration-color: $red;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
		transition: 1s;
	}
}

//SC9 rmv button preset styles to mimic <a>
@mixin btn-to-link-conversion {
	@include link-underline();
	background: transparent;
	border: none;
	color: $red;
	font-family: $primary;
	font-weight: 600;
}

@mixin btn-vis() {
	// sass-lint:disable-block no-duplicate-properties
	@include font-size(1.8);
	font-variant-ligatures: none;
	// sass-lint:disable no-vendor-prefixes
	-webkit-font-variant-ligatures: none;
	// sass-lint:enable no-vendor-prefixes
	background: $btn-grad;
	border: none;
	border-radius: 24px;
	color: $white;
	cursor: pointer;
	display: block;
	font-family: $primary;
	font-weight: 600;
	letter-spacing: -0.56px;
	line-height: 17px;
	padding: 17px 15px 15px;
	text-transform: none;
	transition: all 0.5s ease-in-out;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;

	&:hover {
		background: $btn-hover-grad;
		box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.2);
		color: $white;
		transform: translateY(-1px);
		transition: all 0.3s ease-in-out;
	}
}

@mixin disable-btn() {
	background: $mdgrey;
	cursor: not-allowed;

	&:hover {
		box-shadow: none;
		transform: none;
	}
}

@mixin dot-border-h($alignment: bottom, $position: left, $color: gray) {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 6px;
	background-image: url("img/cp-icons/icon-cp_dot-" + $color + ".svg");
	background-size: 12px 6px;
	background-repeat: repeat-x;
	background-position: left center;

	@if $alignment == bottom {
		bottom: -3px;
	}

	@if $alignment == top {
		top: -3px;
	}

	@if $position == center {
		background-position: center center;
	}
}

@mixin object-fit($fit: fill, $position: null) {
	object-fit: $fit;

	@if $position {
		object-position: $position;
		font-family: "object-fit: #{$fit}; object-position: #{$position}";
	} @else {
		font-family: "object-fit: #{$fit}";
	}
}

@mixin focus($color: $white, $contrast: "none", $offset: 2px) {
	// focus states for desktop
	&:focus {
		outline: none;

		@include break-min($medium2) {
			outline: 1px dotted $color;
			outline-offset: $offset;

			// extra contrast
			@if ($contrast == "dark") {
				box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
			} @else if ($contrast == "light") {
				box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
			}
		}
	}
}
