// sass-lint:disable max-file-line-count

.truett-intro {
	@include align-items(center);
	@include font-size(3.6);
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	letter-spacing: -0.5px;
	line-height: 140%;
	min-height: 800px;
	padding: 100px 0 50px;
	position: relative;
	text-align: center;

	.mobile-only {
		@include font-size(1);
		font-family: $secondary;
		letter-spacing: 2px;
		line-height: 20px;
		margin-bottom: 40px;
		text-transform: uppercase;
	}

	@media only screen and (max-width: $mobile) {
		@include font-size(1.6);
		line-height: 28px;
		min-height: initial;
		overflow: hidden;
		padding: 20px 0 60px;
	}

	.giving-back & {
		background-size: cover;
	}

	.great-food & {
		background-size: cover;
		margin-top: -105px;
		padding-top: 135px;

		@media only screen and (max-width: $mobile) {
			margin-top: -50px;
			padding-bottom: 150px;
			padding-top: 140px;
		}
	}

	.who-we-are & {
		background-size: cover;
		@include font-size(3.6);
		color: $white;
		letter-spacing: -0.5px;
		line-height: 140%;
		margin-top: -105px;
		padding-top: 205px;
		text-shadow: 0 0 40px rgba(0, 0, 0, 1);

		@media only screen and (max-width: $sml-mobile) {
			@include font-size(1.6);
			line-height: 150%;
			margin-top: -50px;
			padding-top: 100px;
		}

		.years {
			color: $white;
		}
	}

	.history & {
		background-size: cover;
		color: $white;
		margin-top: -105px;
		padding-top: 135px;

		@media only screen and (max-width: $mobile) {
			@include font-size(2.8);
			line-height: 150%;
			margin-top: 0;
			padding-bottom: 100px;
			padding-top: 50px;
			text-shadow: 0 0 70px black;
		}

		.wrapper {
			top: 0;
		}

		.years {
			color: $white;
		}
	}

	&.large {
		@include font-size(10);
		letter-spacing: -3px;
		line-height: 130%;

		.wrapper {
			width: $col10;
		}

		@media only screen and (max-width: $mobile) {
			@include font-size(2.8);
			letter-spacing: 0;
			line-height: 150%;
		}
	}

	.wrapper {
		position: relative;
		top: 0;
		width: $col6;
		z-index: 1000;

		@media only screen and (max-width: $mobile) {
			width: $col10;
		}
	}

	.signature {
		margin-top: 40px;
		max-width: 165px;

		@media only screen and (max-width: $mobile) {
			margin-top: 15px;
		}
	}

	.source {
		@include font-size(1);
		font-family: $secondary;
		letter-spacing: 2px;
		line-height: 20px;
		margin-top: 15px;
		text-transform: uppercase;
	}

	.years {
		// sass-lint:disable variable-for-property
		color: #acacac;
		// sass-lint:enable variable-for-property
		display: block;
	}

	.right-hand,
	.left-hand,
	.oats,
	.spoon,
	.honey,
	.placeset,
	.peaches,
	.breakfast,
	.coffee {
		position: absolute;
		right: 0;
		top: 0;
		width: 25%;
		z-index: 500;

		@media only screen and (max-width: $mobile) {
			bottom: -100px;
			top: initial;
		}

		@media only screen and (max-width: 600px) {
			width: 46%;
		}
	}

	.right-hand {
		bottom: 15%;
		text-align: right;
		top: initial;
		width: 25%;

		@media only screen and (max-width: $mobile) {
			bottom: 25%;
			right: -20px;
			width: 35%;
		}
	}

	.left-hand {
		bottom: -10%;
		left: 0;
		text-align: left;
		top: initial;
		width: 39%;

		@media only screen and (max-width: $mobile) {
			bottom: -10px;
			left: -10px;
			top: initial;
			width: 50%;
		}
	}

	.spoon {
		bottom: -4%;
		left: 0;
		top: initial;
		width: 24%;

		@media only screen and (max-width: $mobile) {
			bottom: -20px;
			max-width: 161px;
			top: initial;
			width: 50%;
		}
	}

	.oats {
		@media only screen and (max-width: $mobile) {
			top: 0;
		}
	}

	.honey {
		bottom: -10%;
		top: initial;
		width: 18%;

		@media only screen and (max-width: $mobile) {
			bottom: -40px;
			max-width: 150px;
			top: initial;
			width: auto;
		}
	}

	.placeset {
		bottom: -50px;
		left: 0;
		top: initial;
		width: 18%;

		@media only screen and (max-width: $sml-mobile) {
			bottom: -50px;
			left: -10%;
			width: 30%;
		}
	}

	.peaches {
		bottom: -95px;
		top: initial;
		width: 15%;

		@media only screen and (max-width: $sml-mobile) {
			bottom: -70px;
			width: 25%;
		}
	}

	.breakfast {
		bottom: -6%;
		top: initial;
		width: 30%;

		@media only screen and (max-width: $mobile) {
			bottom: -10px;
		}
	}

	.coffee {
		bottom: 0;
		left: 0;
		right: initial;
		top: initial;

		@media only screen and (max-width: $mobile) {
			bottom: -10px;
		}
	}
}

.next-section {
	@include font-size(16);
	color: $white;
	letter-spacing: -5px;
	line-height: 130%;
	position: relative;
	text-align: center;
	overflow: hidden;

	&:hover {
		cursor: pointer;

		.wrapper {
			background: rgba($black, 0.4);
			transition: background-color 1s ease;
		}

		&.dark {
			color: $dkred;

			a {
				color: $dkred;
			}

			.wrapper {
				background: rgba($white, 0.4);
			}
		}
	}

	@media only screen and (max-width: $lrg-tablet) {
		@include font-size(12);
	}

	@media only screen and (max-width: $mobile) {
		@include font-size(9);
	}

	&.dark {
		color: $dkgrey;

		a {
			color: $dkgrey;
		}
	}

	img {
		width: 100%;
	}

	.wrapper {
		height: 100%;
		max-width: none;
		padding: 4% 0 0;
		position: absolute;
		top: 0;
		transition: background-color 0.5s ease;
		width: 100%;

		@media only screen and (max-width: $mobile) {
			padding-top: 75px;
		}
	}

	.next {
		@include font-size(1);
		font-family: $secondary;
		letter-spacing: 2px;
		line-height: 20px;
		margin-bottom: 70px;
		text-transform: uppercase;

		@media only screen and (min-width: $desktop) {
			margin-bottom: 90px;
		}

		@media only screen and (max-width: $lrg-tablet) {
			margin-bottom: 50px;
		}

		& + p {
			@include align-items(center);
			@include justify-content(center);
			display: flex;
			height: 40%;
			width: 100%;
		}
	}

	a {
		border: 0;
		color: $white;
		letter-spacing: -5px;

		&:hover {
			color: $white;
		}
	}
}

.foundation {
	.cfafoundation {
		padding-top: 50px;
		margin-bottom: 0;

		& > img {
			margin: 0 0 75px;
		}

		.wrapper {
			@media only screen and (max-width: $mobile) {
				padding-bottom: 20px;
				padding-top: 0;
			}
		}

		@media only screen and (max-width: $mobile) {
			display: block;
			margin: 0 auto;
			padding-top: 0;
		}

		h2 {
			@include font-size(4.8);
			letter-spacing: -0.5px;
			line-height: 130%;
			margin: 0 0 30px;
		}
	}

	.wrapper {
		padding-bottom: 20px;

		@media only screen and (max-width: $mobile) {
			padding-bottom: 20px;
			padding-top: 0;
		}

		&.narrow {
			padding-top: 0px;
		}
	}

	h2 {
		@include font-size(3.6);
		letter-spacing: -0.5px;
		line-height: 140%;
		margin: 0 0 20px 0;
	}

	.flex {
		margin-bottom: 50px;

		div {
			font-family: $secondary;
		}
	}

	@media only screen and (max-width: $mobile) {
		padding-bottom: 1px;
	}
}

.giving {
	padding: 50px 0;

	@media only screen and (max-width: $mobile) {
		padding: 10px 0;
	}

	.wrapper {
		@include font-size(1.6);
		line-height: 28px;

		@media only screen and (max-width: $mobile) {
			padding-bottom: 20px;
			padding-top: 0;
		}
	}

	h2 {
		@include font-size(4.8);
		letter-spacing: -0.5px;
		line-height: 130%;
	}
}

.communities {
	h2 {
		@include font-size(4.8);
		letter-spacing: -0.5px;
		line-height: 130%;
	}

	.flex {
		margin-top: 20px;

		& > div {
			font-family: $secondary;

			@media only screen and (max-width: $mobile) {
				margin-bottom: 50px;
			}
		}
	}

	.wrapper {
		@media only screen and (max-width: $mobile) {
			padding-bottom: 20px;
			padding-top: 0;
		}
	}
}

.bkgd-img {
	padding: 0;
	position: relative;

	.content-container {
		padding: 50px 0;
		position: absolute;
		width: 100%;
	}
}

.has-icon {
	font-family: $secondary;
	text-align: center;

	@media only screen and (max-width: $mobile) {
		text-align: left;
	}

	.icon {
		height: 80px;

		@media only screen and (max-width: $mobile) {
			img {
				display: block;
				margin: 0 auto;
			}
		}

		img {
			max-width: 80px;
			width: 100%;
		}
	}
}

.mini-facts {
	@include justify-content(center);
	margin: 30px auto 80px;
	max-width: $main;

	@media only screen and (max-width: $mobile) {
		padding: 0;
	}

	.mini-fact:first-child:nth-last-child(1) {
		margin: 0 auto;
		max-width: $col3;

		@media only screen and (max-width: $mobile) {
			margin: 40px 0;
			max-width: none;
		}
	}

	.mini-fact:first-child:nth-last-child(2),
	.mini-fact:first-child:nth-last-child(2) ~ .mini-fact {
		margin: 0 (2 * $col-margin);
		max-width: $col4;

		@media only screen and (max-width: $mobile) {
			margin: 40px 0;
			max-width: none;
		}
	}

	.mini-fact:first-child:nth-last-child(3),
	.mini-fact:first-child:nth-last-child(3) ~ .mini-fact {
		box-sizing: content-box;
		//margin: 0 1.5*$col-margin;
		margin: 0 $col-margin;
		max-width: $col4;
		// &:nth-child(1) {
		// 	background-color: red;
		// 	margin-left: 0;
		// }
		// &:nth-child(3) {
		// 	margin-right: 0;
		// }
		@media only screen and (max-width: $mobile) {
			margin: 40px auto;
			max-width: $col6;
		}

		@media only screen and (max-width: $sml-mobile) {
			max-width: none;
		}
	}
}

.sunday {
	text-align: center;
	padding: 0;
	position: relative;

	@media only screen and (max-width: $mobile) {
		padding: 0 0 50px 0;
		text-align: left;
	}

	.wrapper {
		bottom: 80px;
		box-sizing: content-box;
		left: 0;
		max-width: none;
		position: absolute;
		right: 0;
		text-align: center;
		width: $col6;

		@media only screen and (max-width: $mobile) {
			text-align: left;
			width: auto;
		}
	}

	.top {
		left: 25%;
		line-height: 1.75;
		position: absolute;
		right: 20%;
		top: 100px;
		width: $col6;

		@media only screen and (max-width: $mobile) {
			left: 0;
			padding: 0 20px;
			right: 0;
			top: 50px;
			width: auto;
		}

		p:last-child {
			@include justify-content(space-around);
			display: flex;

			@media only screen and (max-width: $mobile) {
				display: block;
				margin-top: 40px;

				a {
					margin: 20px 0;
				}
			}

			a {
				display: block;
				width: $col4;

				@media only screen and (max-width: $mobile) {
					width: auto;
				}
			}
		}

		h2 {
			@include font-size(4.8);
			font-weight: 400;
			letter-spacing: -0.5px;
			line-height: 130%;
			margin: 0 0 20px 0;

			@media only screen and (max-width: $mobile) {
				line-height: 1;
			}
		}
	}

	img {
		width: 100%;
	}
}

.purpose,
.leadership {
	@include font-size(2.2);
	line-height: 34px;

	.wrapper {
		width: $col7;

		@media only screen and (max-width: $mobile) {
			width: auto;
		}
	}
}

.purpose {
	@media only screen and (max-width: $mobile) {
		text-align: center;

		.wrapper {
			margin-top: 0;
			padding-bottom: 0;
			padding-top: 0;
		}
	}
}

.leadership {
	@include font-size(1.6);
	letter-spacing: initial;
	line-height: 28px;
	padding-bottom: 0;

	.mini-facts {
		@media only screen and (max-width: $mobile) {
			margin: 0;
			padding: 0;
		}

		.mini-fact {
			@include font-size(1.6);
			line-height: 28px;
			text-align: center;

			@media only screen and (max-width: $mobile) {
				text-align: left;
				margin: 20px 0;
				padding: 0;

				br {
					display: none;
				}
			}
		}
	}

	h2,
	p {
		margin-bottom: 65px;
	}

	@media only screen and (max-width: $mobile) {
		margin-top: 0;
		padding-top: 0;

		.wrapper {
			padding-bottom: 0;
		}
	}
}

.family-owned {
	@media only screen and (max-width: $mobile) {
		.wrapper {
			margin: 0;
		}
	}

	.mini-fact {
		@include font-size(2.2);
		line-height: 34px;
		padding-bottom: 120px;
		position: relative;
		text-align: center;

		img {
			margin-bottom: 35px;
		}

		@media only screen and (max-width: $mobile) {
			text-align: left;
		}
	}

	.source {
		@include font-size(1.2);
		bottom: 0;
		font-family: $secondary;
		line-height: 20px;
		position: absolute;
		width: 100%;

		h3 {
			@include font-size(2.4);
			letter-spacing: -0.5px;
			line-height: 30px;
			font-weight: 700;
			margin: 0;
		}

		p {
			margin: 5px auto;
			max-width: $col6;

			@media only screen and (max-width: $mobile) {
				margin: 5px 0;
			}
		}
	}
}

.next-gen {
	margin: 0 auto;
	max-width: $col6;
	padding-top: 100px;

	@media only screen and (max-width: $mobile) {
		margin: 0;
		max-width: none;
	}

	h2 {
		@include font-size(3.6);
		letter-spacing: -0.5px;
		line-height: 140%;
	}
}

.awards {
	@media only screen and (max-width: $mobile) {
		.wrapper {
			margin: 0;
			padding-top: 0;
		}
	}

	h2 {
		margin-bottom: 60px;
	}

	.b-logo {
		display: flex;
		margin-bottom: 40px;
		@include align-items(center);

		img {
			margin: 0 auto;
		}
	}

	.award-blurb {
		font-family: $secondary;
		margin-bottom: 50px;

		h3 {
			@include font-size(2.4);
			letter-spacing: -0.5px;
			line-height: 30px;
			font-family: $secondary;
			font-weight: 700;
		}
	}

	h3 {
		font-weight: 700;
	}
}

.football {
	h3 {
		@include font-size(2.4);
		font-weight: 700;
		letter-spacing: -0.5px;
		line-height: 30px;
	}

	@media only screen and (max-width: $mobile) {
		padding-bottom: 1px;

		.wrapper {
			margin: 0;
			padding-bottom: 0;
			padding-top: 0;
		}
	}

	.mini-facts {
		font-family: $secondary;
		margin: 100px 0;

		@media only screen and (max-width: $mobile) {
			padding: 0;
		}
	}

	.mini-fact:first-child:nth-last-child(2),
	.mini-fact:first-child:nth-last-child(2) ~ .mini-fact {
		margin: 0 (2 * $col-margin);
		max-width: $col4;

		@media only screen and (max-width: $mobile) {
			margin: 40px 0;
			max-width: none;
		}

		&:nth-child(1) {
			margin-left: 0;
		}

		&:nth-child(2) {
			margin-right: 0;
		}
	}
}

.partner-logo {
	margin: 0;
	text-align: center;
}

.innovation {
	// sass-lint:disable variable-for-property
	background-color: #274a8b;
	// sass-lint:disable variable-for-property
	color: $white;
	overflow: hidden;
	padding-bottom: 250px;
	position: relative;

	@media only screen and (max-width: $mobile) {
		padding-bottom: 500px;
	}

	&:before {
		@include scrim($scrim);
	}

	h2 {
		margin-top: 50px;
		margin-bottom: 90px;
	}

	@media only screen and (max-width: $mobile) {
		text-align: center;

		.wrapper {
			margin-top: 0;
			padding-top: 0;
		}
	}

	.mini-fact {
		font-family: $secondary;
		text-align: center;
	}

	.wrapper {
		position: relative;
		z-index: 200;
	}

	picture {
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: 100;
	}

	.innovation-icon {
		text-align: center;
	}

	h3 {
		@include font-size(2.4);
		font-family: $secondary;
		font-weight: 700;
		letter-spacing: -0.5px;
		line-height: 30px;
	}

	a {
		color: $white;
		font-weight: 600;
	}
}

.legacy {
	@media only screen and (max-width: $mobile) {
		&.co-about {
			display: none;
			padding: 0;
		}
	}

	.wrapper {
		max-width: $col8;

		@media only screen and (max-width: $mobile) {
			max-width: none;
			margin: 0;
		}
	}

	.story {
		font-family: $secondary;
		text-align: left;

		p {
			margin: 0 0 15px 0;
		}

		h3 {
			@include font-size(2.4);
			font-weight: 700;
			letter-spacing: -0.5px;
			line-height: 30px;
			margin: 45px 0 15px;
		}
	}

	.flex {
		margin-top: 50px;

		.story {
			font-family: $secondary;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.about {
	picture {
		img {
			height: auto;
			max-height: none;
			width: 100%;
		}
	}

	&.giving-back {
		.frame {
			padding-top: 0;
		}
	}

	&.who-we-are {
		.frame {
			padding-top: 0;
		}
	}
}

.alt-layout {
	padding: 0;
	position: relative;

	img {
		width: 100%;
	}

	.content {
		left: 15%;
		position: absolute;
		top: 20%;
		text-align: left;
		width: $col3;
	}
}

.black {
	.great-food & {
		// sass-lint:disable variable-for-property
		background-color: #393021;
		// sass-lint:disable variable-for-property
	}

	.mini-fact {
		@media only screen and (max-width: $mobile) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
