// sass-lint:disable no-ids, max-file-line-count, no-qualifying-elements

.co-tod {
	padding-top: 137px;
	position: relative;
	text-align: center;

	@media only screen and (max-width: $mobile) {
		padding-top: 87px;
	}

	img {
		height: auto;
		margin-top: 175px;
		max-height: 100%;
		max-width: 100%;

		@media only screen and (max-width: $mobile) {
			margin-top: 10px;
			max-height: 95%;
		}
	}

	&.lrgimg {
		overflow: hidden;

		@media only screen and (max-width: $mobile) {
			@include flex-direction(column);
			@include justify-content(flex-end);
			display: flex;
		}

		img {
			height: auto;
			margin: 0;
			max-height: none;
			width: 100%;
		}

		.co-tagline {
			position: static;

			@media only screen and (max-width: $lrg-tablet) {
				top: 30%;
			}
		}

		& + #menu {
			background-color: $bkgrey;
			padding-top: 50px;
		}

		.btn {
			margin-bottom: 150px;
			position: relative;
			z-index: 5000;
		}
	}

	&.fullsize.lrgimg {
		.co-content {
			@include align-items(center);
			@include justify-content(center);
			background-position: bottom center;
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

.co-tagline {
	@include align-items(center);
	@include font-size(18);
	@include justify-content(center);
	color: $white;
	display: flex;
	height: 100%;
	letter-spacing: -5px;
	line-height: 130%;
	position: absolute;
	text-align: center;
	text-shadow: 0px 0px 50px $grey;
	top: 0;
	width: 100%;

	@media only screen and (max-width: $mobile) {
		@include font-size(8);
	}
}

.co-item {
	.image {
		width: $col9;

		@media only screen and (max-width: $mobile) {
			width: auto;
		}
	}

	.content {
		@include font-size(1.4);
		font-family: $secondary;
		line-height: 1.4;
		margin-left: $col-margin;
		width: $col3;

		@media only screen and (max-width: $mobile) {
			display: none;
		}
	}

	img {
		max-width: 100%;
		width: 100%;
	}

	.title {
		@include font-size(2.8);
		color: $blue;
		font-family: $primary;
		letter-spacing: 0;
		line-height: 150%;
	}

	h2.title {
		@include font-size(2.8);
		color: $blue;
		font-family: $primary;
		letter-spacing: 0;
		line-height: 150%;
		margin-bottom: 15px;
		border-bottom: 0;
		width: auto;
		position: static;
		height: auto;
	}

	h2.title:after {
		content: "";
	}

	.btn {
		@include font-size(1.6);
		line-height: 28px;
	}

	.item-title {
		@include font-size(2.4);
		letter-spacing: -0.5px;
		line-height: 30px;
	}

	&.right {
		.image {
			@include order(2);
			margin-left: $col-margin;

			@media only screen and (max-width: $mobile) {
				margin-left: 0;
			}
		}

		.content {
			@include order(1);
			margin-left: 0;
		}

		.item-title {
			text-align: right;
		}
	}
}

.catering-target {
	&.item-left {
		.image {
			order: 1;
		}

		.content {
			order: 2;

			@media only screen and (max-width: $mobile) {
				margin-top: 50px;
			}
		}
	}

	.flex {
		align-items: center;
		justify-content: space-between;

		@media only screen and (max-width: $mobile) {
			flex-direction: column;
			text-align: center;
		}
	}

	.image {
		width: $col7;

		@media only screen and (max-width: $mobile) {
			width: $col11;
		}
	}

	.content {
		@include font-size(2.2);
		color: $search-grey;
		width: $col5;

		@media only screen and (max-width: $mobile) {
			width: $col11;
		}
	}

	.title {
		@include font-size(4);
		font-weight: 600;
	}

	p {
		margin-bottom: 30px;
	}
}

.wood-panel1 {
	background: url("img/wood-panel1.jpg") transparent center no-repeat;
}

.wood-panel2 {
	background: url("img/wood-panel2.jpg") transparent top right no-repeat;
	background-size: cover;
}

[class^="wood-panel"],
[class*=" wood-panel"] {
	background-size: cover;
	padding: 75px 0;

	&.fullsize {
		padding: 0;
	}
}

.co-app {
	background-color: $white;
	border-top: 1px solid $bkgrey;
	padding: 25px 0 25px;

	.flex {
		@include align-items(center);
		@include justify-content(center);
	}

	@media only screen and (max-width: $mobile) {
		background-color: $bkgrey;
		border-color: $white;
		text-align: center;
		padding: 40px 0;

		.flex {
			display: block;
		}
	}

	img {
		@media only screen and (max-width: $mobile) {
			margin-bottom: 30px;
		}
	}

	.blurb {
		line-height: 1.33;
		margin-left: $col-margin;
		width: $col3;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 20px;
			margin-left: 0;
			width: auto;
		}

		@media only screen and (max-width: $sml-desktop) {
			@include font-size(1.4);
		}

		@media only screen and (max-width: $lrg-tablet) {
			@include font-size(1.6);
			line-height: 28px;
		}
	}

	.app-form,
	.app-stores,
	.more {
		margin-left: $col-margin;
		white-space: nowrap;

		@media only screen and (max-width: $lrg-tablet) {
			margin-left: 0;
		}
	}

	.more {
		@media only screen and (max-width: $lrg-tablet) {
			margin-bottom: 10px;

			.btn {
				background-color: transparent;
				color: $red;
				padding: 0;
			}
		}
	}

	.app-stores {
		background: url("img/dots.png") transparent top left no-repeat;
		background-size: 3px;
		padding-left: $col-margin;

		@media only screen and (max-width: $mobile) {
			background-image: none;
			padding-left: 0;
		}

		a {
			border: 0;
			margin-top: 5px;

			&:hover {
				border: 0;
			}

			&:first-child {
				margin-right: 10px;
			}
		}

		.btn {
			display: none;
		}
	}

	label {
		@include visuallyhidden;
	}

	input {
		&[type="text"] {
			background-color: $yellow;
			border: 0;
			border-radius: 5px;
			color: $grey;
			font-family: $primary;
			margin-right: 5px;
			padding: 13px 10px 8px;
			text-align: center;
			width: 250px;

			@media only screen and (max-width: $mobile) {
				margin-bottom: 15px;
			}
		}

		@include placeholder {
			// sass-lint:disable variable-for-property
			color: #e3dab8;
			// sass-lint:enable variable-for-property
			font-family: $primary;
		}
	}

	@media only screen and (max-width: $mobile) {
		.ipad &,
		.iphone & {
			.apple,
			.google {
				display: none;
			}

			.ios {
				display: inline-block;
			}
		}
	}

	@media only screen and (max-width: $lrg-tablet) {
		.android & {
			.apple,
			.google {
				display: none;
			}

			.android {
				display: inline-block;
			}
		}
	}

	@media only screen and (max-width: $lrg-tablet) {
		.w-phone & {
			.apple,
			.google {
				display: none;
			}

			.windows {
				display: inline-block;
			}
		}
	}
}

.apple {
	@include fir("img/itunes-store.png", 44px, 150px);
}

.google {
	@include fir("img/google-play.png", 44px, 150px);
}

.co-social {
	overflow: hidden;
	position: relative;

	@media only screen and (max-width: $mobile) {
		display: none;
	}

	&.alt {
		background: url("img/social-wood.jpg") transparent center center no-repeat;
		background-size: cover;
		padding: 50px 0;
		text-align: center;

		@media only screen and (max-width: $mobile) {
			display: block;
		}

		.title {
			@include font-size(6.4);
			color: $red;
			margin: 0;

			@media only screen and (max-width: $mobile) {
				@include font-size(4.8);
				letter-spacing: -0.5px;
				line-height: 130%;
			}
		}

		.tagline {
			@include font-size(1);
			font-family: $secondary;
			letter-spacing: 2px;
			line-height: 20px;
			text-transform: uppercase;
		}
	}

	img {
		height: auto;
		width: 100%;
	}

	.tweet {
		@include justify-content(center);
		@include flex-direction(column);
		@include font-size(2.8);
		box-sizing: border-box;
		bottom: 0;
		color: $white;
		display: flex;
		left: 0;
		letter-spacing: 0;
		line-height: 150%;
		position: absolute;
		right: 0;
		text-shadow: 0px 0px 70px black;
		top: 0;
		z-index: 200;
		width: 50%;
	}

	.user {
		@include font-size(1.6);
		line-height: 28px;
	}
}

.fullsize {
	.co-content {
		box-sizing: border-box;
		height: 100%;
		padding: 0;
		position: relative;
		text-align: center;
		width: 100%;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 25px;
		}
	}
}

.scroller {
	@include font-size(1.6);
	bottom: 0;
	color: $red;
	display: inline-block;
	left: 0;
	line-height: 28px;
	padding: 20px 0;
	position: absolute;
	right: 0;
	text-align: center;
	width: 100%;

	.home & {
		&:hover {
			cursor: pointer;
			animation: pulse 1s;
		}
	}

	.fullsize.lrgimg & {
		color: $white;
	}

	@media only screen and (max-width: $mobile) {
		bottom: 5px;
	}

	.icon-circle-down {
		&:before {
			@include font-size(2);
			vertical-align: middle;
		}
	}

	&:hover {
		border-color: transparent;
	}
}

.co-bite {
	// sass-lint:disable-block max-line-length
	@include font-size(1.2);
	background: url("img/product-wood-clean.jpg") transparent center bottom repeat;
	font-family: $secondary;
	line-height: 20px;
	margin: 0 auto;
	padding: 30px 0;
	text-align: center;

	.wrapper {
		max-width: 600px;

		@media only screen and (max-width: $mobile) {
			max-width: none;
			width: $col9;
		}
	}

	h3 {
		@include font-size(5);
		color: $blue;
		font-family: $primary;
		line-height: 1.1;
		margin: 0 0 20px 0;

		@media only screen and (max-width: $mobile) {
			@include font-size(4.8);
			letter-spacing: -0.5px;
			line-height: 130%;
		}
	}

	p {
		margin: 0 auto 40px;
		max-width: 70%;

		@media only screen and (max-width: $mobile) {
			max-width: none;
		}
	}

	.product-page & {
		margin-top: 0;
	}
}

.grey {
	background-color: $bkgrey;
}

.black {
	// sass-lint:disable variable-for-property
	background-color: #171210;
	// sass-lint:enable variable-for-property
	color: $white;

	a {
		color: $white;
		font-weight: 600;

		&:hover {
			color: $white;
		}
	}
}

.co-about {
	padding: 50px 0;
	text-align: center;

	@media only screen and (max-width: $mobile) {
		text-align: left;
		padding-bottom: 0;

		.read-more {
			margin-bottom: 50px;
		}
	}

	.foundation-blurb {
		p {
			font-family: $primary;
		}
	}

	&.gb-grad {
		background: url("img/handmade-grad.jpg") transparent top left no-repeat;
		background-size: cover;
		overflow: hidden;

		@media only screen and (max-width: $mobile) {
			padding: 1px 0;
		}

		.wrapper {
			padding-bottom: 0;
			padding-top: 0;

			@media only screen and (max-width: $mobile) {
				margin-top: -1px;
			}

			.content {
				@include flex-direction(column);
				@include justify-content(center);
				display: flex;
				padding-bottom: 40px;
				padding-top: 40px;
				max-width: $col4;

				@media only screen and (max-width: $mobile) {
					color: $white;
					max-width: none;
					padding: 0 30px 40px;
					width: auto;

					a {
						color: $white;
						font-weight: 700;
					}
				}
			}

			.img {
				position: relative;
				text-align: right;

				@media only screen and (max-width: $mobile) {
					text-align: center;
				}

				img {
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
					max-width: none;
					width: auto;

					@media only screen and (max-width: 1350px) {
						left: 0;
						right: auto;
					}

					@media only screen and (max-width: $mobile) {
						position: static;
						height: auto;
						width: 100%;
						margin-bottom: 20px;
					}
				}
			}
		}

		p {
			line-height: 30px;
			margin-bottom: 20px;
		}

		.img {
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
			margin: 0;
		}

		@media only screen and (max-width: $mobile) {
			background-color: $black;
			background-image: none;
			color: $white;

			.wrapper {
				padding: 0;
			}

			.flex {
				@include flex-direction(column);
				display: flex;
			}

			.img {
				@include order(1);
			}

			.content {
				@include order(2);
				padding: 15px;
			}
		}
	}

	&.full {
		padding: 0 100px;

		@media only screen and (max-width: $mobile) {
			padding: 0;
		}

		.content {
			max-width: $col6;

			@media only screen and (max-width: $mobile) {
				max-width: none;
			}
		}
	}

	&.padded {
		padding: 25px 50px;

		@media only screen and (max-width: $mobile) {
			padding: 25px 20px;
		}

		.flex {
			@media only screen and (max-width: $mobile) {
				@include flex-direction(column);
				display: flex;
				padding: 0;

				.wrapper {
					margin-bottom: 0;
					margin-top: 0;
				}
			}
		}

		& > h2 {
			@include font-size(4.8);
			letter-spacing: -0.5px;
			line-height: 130%;
			margin-top: 50px;

			@media only screen and (max-width: $mobile) {
				margin-top: 0;
				padding: 0 15px;
			}
		}

		.content,
		.img {
			margin: 0;
			padding: 50px 0;
		}

		.img {
			margin-left: $col-margin;
			padding-top: 0;
			padding-left: 75px;
			text-align: right;

			@media only screen and (max-width: $mobile) {
				@include order(1);
				margin: 0;
				padding: 0;
			}
		}

		.content {
			@media only screen and (max-width: $mobile) {
				@include order(2);
			}
		}

		h3 {
			@include font-size(2.4);
			font-family: $secondary;
			font-weight: 700;
			letter-spacing: -0.5px;
			line-height: 30px;
			margin: 0 0 1em 0;
		}

		h2 {
			& + h3 {
				margin-top: 0;
			}
		}

		.small {
			@include font-size(1.2);
			font-family: $secondary;
			letter-spacing: 2px;
			line-height: 20px;
			text-transform: uppercase;
		}

		.wrapper {
			@media only screen and (min-width: $lrg-tablet) {
				&:nth-child(even) {
					.img {
						@include order(1);
						margin-left: 0;
						padding-left: 0;
						padding-right: 75px;
						text-align: left;
					}

					.content {
						@include order(2);
						margin-left: $col-margin;
					}
				}
			}
		}
	}

	&.alt {
		padding-top: 50px;
		padding-bottom: 0;

		.wrapper {
			left: 0;
			right: 0;
			position: absolute;
			width: 100%;

			@media only screen and (max-width: $mobile) {
				position: static;
				margin-top: 0;
			}
		}

		@media only screen and (max-width: $mobile) {
			padding-top: 0;

			img {
				width: 100%;
			}
		}
	}

	&.truett-memorial {
		background-color: $white;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: left;
		padding: 100px 0;
		overflow: hidden;

		@media only screen and (max-width: 1040px) {
			background-size: contain;
		}

		@media only screen and (max-width: $mobile) {
			background-size: cover;
			padding: 170% 0 0px;
		}

		.wrapper {
			@include justify-content(flex-end);
			display: flex;
			padding: 0;
		}

		.content {
			padding: 50px;
			max-width: $col4;

			@media only screen and (max-width: $mobile) {
				float: none;
				margin-left: 0;
				max-width: none;
				padding: 20px 25px;
			}
		}

		.dates {
			@include font-size(1);
			font-family: $secondary;
			letter-spacing: 2px;
			line-height: 20px;
			margin-bottom: 50px;
		}

		h2 {
			margin-bottom: 10px;
		}

		img {
			float: left;
			max-width: 50%;

			@media only screen and (max-width: $mobile) {
				float: none;
				max-width: 100%;
				width: 100%;
				height: 100%;
			}
		}
	}

	&.history {
		background-color: $bkgrey;

		.wrapper {
			box-sizing: border-box;
			padding-left: 50px;
			padding-right: 50px;

			@media only screen and (max-width: $lrg-tablet) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		@media only screen and (max-width: $mobile) {
			.wrapper {
				margin: 0;
			}
		}

		.flex {
			@include justify-content(space-between);
			margin-top: 75px;
			padding: 0 0 0 25px;

			& > div {
				@include flex(none);
				margin: 0;
			}

			.left {
				padding-left: 25px;
				width: $col2;
			}

			.right {
				width: $col10;
			}

			.history-toggle {
				width: auto;
			}
		}

		.history-module {
			@include justify-content(center);
			display: flex;
			margin-left: $col-margin;

			@media only screen and (max-width: $mobile) {
				display: block;
				margin: 0;
				width: $col11;
			}

			h3 {
				@include font-size(2.8);
				letter-spacing: 0;
				line-height: 150%;
			}

			.img {
				margin-right: 2 * $col-margin;
				animation: fadeIn 1s;

				@media only screen and (max-width: $sml-desktop) {
					width: $col7;
				}

				@media only screen and (max-width: $mobile) {
					margin: 80px 0 0 0;
					width: 100%;

					img {
						width: 100%;
					}
				}
			}

			.content {
				animation: fadeIn 2.5s;
				width: $col3;

				@media only screen and (max-width: $sml-desktop) {
					width: $col4;
				}

				@media only screen and (max-width: $mobile) {
					margin: 20px 0;
					width: auto;
				}
			}
		}
	}

	&.bkgd-img {
		picture {
			img {
				width: 100%;
			}
		}
	}

	.wrapper {
		padding-bottom: 38px;
		padding-top: 50px;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 40px;
			margin-top: 40px;
		}

		&.narrow {
			width: $col6;

			@media only screen and (max-width: $mobile) {
				width: auto;
			}
		}
	}

	.flex {
		text-align: left;

		@media only screen and (max-width: $mobile) {
			display: block;
			margin-bottom: 0;
		}

		& > div {
			@include flex(1);
			margin: 0 (1.5 * $col-margin);

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			@media only screen and (max-width: $mobile) {
				margin: 0;
			}
		}

		&.three {
			& > div {
				width: $col4;

				@media only screen and (max-width: $mobile) {
					width: auto;
				}
			}
		}
	}

	h2 {
		@include font-size(4.8);
		letter-spacing: -0.5px;
		line-height: 130%;
		margin: 0 0 30px;
	}

	.section-title {
		@include font-size(2.4);
		font-family: $secondary;
		font-weight: 700;
		letter-spacing: -0.5px;
		line-height: 30px;
		margin-top: 20px;
	}

	p {
		line-height: 1.8;
	}

	ul {
		font-family: $secondary;
		list-style: none;
		padding: 0;

		&.history-toggle {
			margin-top: 0;

			li {
				text-indent: 0;
				margin: 20px 0;
				line-height: 1.5;
			}
		}

		li {
			margin: 0 0 10px 33px;
			text-indent: -30px;

			@media only screen and (max-width: $mobile) {
				text-indent: -38px;
			}

			span {
				padding-left: 30px;
				background-image: url("img/bullet.png");
				background-repeat: no-repeat;
				background-position: left center;
				background-size: 6px;
			}
		}

		&.social-icons {
			li {
				text-indent: 0;
				margin: 0 15px 0 0;

				&:before {
					display: none;
				}
			}

			@media only screen and (max-width: $mobile) {
				text-align: left;
			}
		}
	}
}

.stat {
	@include font-size(1);
	letter-spacing: 2px;
	line-height: 20px;
	position: relative;
	text-align: center;

	.wrapper {
		padding: 0;
	}

	.stat-details {
		max-width: none;
		position: absolute;
		top: 180px;
		width: 100%;

		@media only screen and (max-width: $mobile) {
			position: static;
			padding-bottom: 40px;
		}

		p {
			line-height: 0;
			letter-spacing: 4px;
			margin: 0;

			@media only screen and (max-width: $mobile) {
				margin-top: -10px;
				line-height: 1.4;
			}
		}
	}

	h2 {
		@include font-size(18);
		font-weight: 300;
		letter-spacing: -5px;
		line-height: 0.8;
		margin: 0;
		padding: 0;

		@media only screen and (max-width: $mobile) {
			@include font-size(10);
			letter-spacing: -3px;
			line-height: 130%;
		}
	}

	p {
		font-family: $secondary;
		text-transform: uppercase;
	}
}

.co-full-img {
	height: 100%;

	img {
		height: 100%;
		width: 100%;
	}
}

.top-hero {
	background-size: cover;
	color: $white;
	height: 340px;
	text-align: center;

	@media only screen and (max-width: $mobile) {
		min-height: 200px;
	}

	.wrapper {
		z-index: 500;
	}

	h1 {
		@include font-size(10);
		letter-spacing: -3px;
		line-height: 130%;
		font-weight: 400;
		margin: 0;

		@media only screen and (max-width: $mobile) {
			@include font-size(4.8);
			letter-spacing: -0.5px;
		}
	}

	&.rep-hero {
		height: 400px;
	}

	&.short {
		height: 310px;

		@media only screen and (max-width: $mobile) {
			margin-top: 0;
			height: auto;
		}

		h1 {
			@include type-h1();
		}

		.btn {
			&.white {
				border-color: transparent;

				&:hover {
					border-color: transparent;
				}
			}
		}
	}
}

.circle-icons {
	@include justify-content(center);
	display: flex;
	padding: 50px 0;

	@media only screen and (max-width: $mobile) {
		@include flex-wrap(wrap);
		padding: 20px 0;
	}

	a {
		border: 0;
		display: block;
		margin: 0 $col-margin;

		@media only screen and (max-width: $mobile) {
			margin: $col-margin;
			width: $col5;
		}
	}
}

.cs-cta {
	background: url("img/cs-wood.jpg") transparent top left no-repeat;
	background-size: cover;
	padding: 100px 0;

	@media only screen and (max-width: $mobile) {
		padding: 50px 0;
	}

	.wrapper {
		@include align-content(stretch);
		display: flex;
		max-width: $col8;

		@media only screen and (max-width: $mobile) {
			display: block;
			max-width: none;
			text-align: center;
		}
	}

	.cs-logo {
		margin-right: 2 * $col-margin;

		@media only screen and (max-width: $mobile) {
			margin: 20px 0;
		}
	}

	.content {
		line-height: 1.7;
		width: $col5;

		@media only screen and (max-width: $mobile) {
			padding: 0 50px;
			width: auto;
		}
	}
}

.cs-logo {
	@include fir("img/cs-logo.png", 200px, 200px);
}

.mini-blurb {
	@include font-size(1.2);
	font-family: $secondary;
	line-height: 20px;
	margin: 50px 0;
	text-align: center;

	@media only screen and (max-width: $mobile) {
		text-align: left;
	}

	h3 {
		@include font-size(2.2);
		font-family: $primary;
		line-height: 34px;
	}
}

.video {
	margin: 25px 0 50px;

	p {
		line-height: 1.2;
		margin: 0 0 10px 0;
	}

	.title {
		@include font-size(3.6);
		letter-spacing: -0.5px;
		line-height: 140%;
		margin-top: 15px;
	}
}

.no-meal {
	@include font-size(1.6);
	background-color: $red;
	color: $white;
	line-height: 28px;
	padding-top: 50px;

	p {
		line-height: 1.8;
		margin: 0 0 30px 0;
	}

	.flex {
		@include align-items(center);
		@include justify-content(space-between);
		// reduced the padding top and bottom for CFACOMFOUR-1648
		padding-bottom: 0;
		padding-top: 60px;
		width: $col8;

		@media only screen and (max-width: $lrg-tablet) {
			width: $col10;
		}

		@media only screen and (max-width: $mobile) {
			text-align: center;
			display: block;
			width: auto;
		}
	}

	.content {
		text-align: center;
		// increased the content width for CFACOMFOUR-1648
		width: $col6;

		@media only screen and (max-width: $mobile) {
			width: auto;
		}
	}

	.location {
		@include font-size(3.6);
		letter-spacing: -0.5px;
		line-height: 140%;
		margin-bottom: 10px;
	}

	// added img class to reduce the image height for CFACOMFOUR-1648
	img {
		height: 352px;
	}
}

.promo-cta {
	@include align-items(center);
	background-color: $white;
	background-position: center;
	background-size: cover;
	display: flex;
	overflow: hidden;
	padding-top: 75px;
	position: relative;

	@media only screen and (max-width: $mobile) {
		background-position: center bottom;
		flex-direction: column;
		padding-bottom: 11px;
	}

	.promo-frame {
		padding: 0 20px;
		max-width: $main;
	}

	.promo-content {
		@include font-size(1.6);
		letter-spacing: 0;
		line-height: 28px;
		position: relative;
		width: $col6;
		z-index: 1000;

		@media only screen and (max-width: $mobile) {
			text-align: center;
			width: $col12;
		}
	}

	img {
		width: auto;
	}

	.co-content {
		padding: 0 20px;
	}

	.title {
		@include font-size(8);
		letter-spacing: -0.5px;
		line-height: 120%;

		@media only screen and (max-width: $mobile) {
			@include font-size(4.8);
		}
	}

	.teaser {
		@include font-size(2.2);
	}

	.btn {
		margin-right: 25px;

		@media only screen and (max-width: $mobile) {
			display: inline-block;
		}
	}

	.scroller {
		z-index: 2000;
	}

	&.white {
		&:before {
			@include scrim($scrim4);

			@media only screen and (max-width: $mobile) {
				@include background($scrim5);
			}
		}

		.scroller {
			color: $red;
		}
	}

	&.dark {
		color: $white;

		a {
			color: $white;
			font-weight: 600;
		}

		&:before {
			@include scrim($scrim2);

			@media only screen and (max-width: $mobile) {
				@include background($scrim5);
			}
		}

		.scroller {
			color: $white;
		}
	}

	&.left {
		.co-tagline {
			@include justify-content(flex-start);
			text-align: left;
		}
	}

	&.lrgimg {
		.co-content {
			@include align-items(flex-start);
			@include justify-content(flex-start);
		}
	}
}

.hero-tagline {
	color: $black;
	padding: 40px 0 20px;
	text-align: center;

	@media only screen and (max-width: $mobile) {
		display: none;
	}

	.wrapper {
		@include font-size(2);
		line-height: 1.8;
		max-width: $col8;

		@media only screen and (max-width: $mobile) {
			max-width: none;
		}
	}
}

.catering-promo {
	color: $white;
	position: relative;
	overflow: hidden;

	&:before {
		@include scrim($scrim2);
		background: transparent;
		transition: background 0.5s ease-in-out;
	}

	&.wire-hovered {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(255, 255, 255, 0.9);
			transition: background 0.5s ease-in-out;
		}
	}

	.promo-content {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 30px;
		z-index: 3000;
	}

	a {
		color: $white;
		font-weight: 600;
	}

	.wire-logo {
		cursor: pointer;

		&.shadow {
			filter: drop-shadow(2px 2px 2px #d2d4d4);
			transition: all 0.5s ease;
		}

		@media screen and (max-width: $mobile) {
			cursor: default;
		}
	}

	.wire-title {
		display: none;
		color: $grey;
		margin-left: 10px;
	}

	.wire-hover-content {
		display: none;

		.hover-inner {
			position: absolute;
			z-index: 999;
			color: $grey;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 620px;

			.title-small {
				font-size: 2rem;
				text-align: center;
				position: relative;
			}

			.title-large {
				font-size: 4rem;
				text-align: center;
				position: relative;
			}
		}
	}

	.bottom-content {
		bottom: 20px;
		position: absolute;
		width: $col4;

		@media only screen and (max-width: $mobile) {
			width: auto;
		}
	}

	picture {
		img {
			width: $col12;
		}
	}

	.tag {
		@include font-size(1.2);
		font-family: $secondary;
		font-weight: 600;
		letter-spacing: 3px;
		line-height: 20px;
		text-transform: uppercase;

		@media only screen and (max-width: $mobile) {
			margin-bottom: 0;
		}
	}

	.title {
		@include font-size(4.8);

		@media only screen and (max-width: $mobile) {
			@include font-size(2.8);
		}
	}
}

.cfa1-promo {
	background-color: $white;
	box-shadow: 0 -4px 26px -6px rgba(0, 0, 0, 1);
	bottom: -100%;
	display: none;
	text-align: center;
	padding: 30px 0;
	position: fixed;
	transition: bottom 1s ease-out;
	width: 100%;
	z-index: 3000;

	@media only screen and (max-width: $mobile) {
		display: block;
	}

	&.open {
		bottom: 0;
	}

	img {
		margin: 0 0 30px;
	}

	.wrapper {
		position: relative;
	}

	.icon-close {
		background-color: transparent;
		border: 0;
		color: $red;
		position: absolute;
		right: 20px;

		span {
			@include visuallyhidden();
		}
	}

	.app-stores {
		margin: 30px 0 10px;
	}
}
