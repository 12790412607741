// sass-lint:disable no-qualifying-elements

@import "../common/main-nav/main-nav";

.main-nav {
	.iphone & {
		padding: $nav-base-height 0 120px;
	}

	@media only screen and (max-width: $mobile) {
		padding: $nav-base-height 0;

		& > ul {
			li {
				border-bottom: 1px solid $concrete;
			}
		}
	}

	.icon-arrow-down {
		&::before {
			@include transform(rotate(270deg));
		}
	}

	> ul.no-search {
		@media only screen and (max-width: $mobile) {
			padding: 8px 4px 0;
		}
	}

	> ul {
		@media only screen and (max-width: $mobile) {
			padding-top: 75px;
		}
	}

	.order-online {
		padding: 8px 16px;

		@include break-min($large1) {
			padding: 0 20px;
		}
	}
}

// CA Food Feature Section
.feature-card {
	margin: 0;
	padding: 20px 0;
	width: 100%;

	.flex {
		& > a.feature-card-container {
			@include font-size(1.6);
			border-radius: 8px;
			background-color: $warm-white;
			font-family: $primary;
			margin-left: $col-margin;
			text-align: center;
			height: 138px;
			width: $col6;
			display: flex;
			justify-content: center;
			flex-flow: column;
			@include font-size(1.6);
			@include link-underline();
			color: $red;
			font-weight: 600;
			line-height: 30px;

			@media only screen and (max-width: $mobile) {
				@include font-size(1.4);
				padding: 0;
			}

			@media only screen and (min-width: $lrg-tablet) {
				height: 160px;
			}

			&:first-child {
				margin-left: 0;
			}

			&:hover {
				background-color: $white;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
				cursor: pointer;
			}
		}
	}
}

// Feature card icons

// Sandwich
.icon-food {
	border: 0;
	color: $red;
	position: relative;
	text-decoration: none;
	height: 52px;
	width: 76px;
	margin: 0 auto;

	@media only screen and (max-width: $mobile) {
		height: 43px;
		width: 64px;
	}

	&::after {
		content: "";
		background-image: url("img/icon_food.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
	}
}

// Tree nut
.icon-allergen {
	border: 0;
	color: $red;
	position: relative;
	text-decoration: none;
	height: 53px;
	width: 40px;
	margin: 0 auto;

	&::after {
		content: "";
		background-image: url("img/icon_tree-nut.svg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
	}
}

// Styles for CA Main Nav Mobile Menu
@media only screen and (max-width: $mobile) {
	.main-nav {
		& li a {
			@include font-size(1.6);
		}

		&.canada {
			@include break-max($large1) {
				padding: 64px 0;
			}

			@include break-max($large1) {
				.nav-shown & {
					.location-tools {
						margin: 30px 0 0 14px;
					}
				}
			}

			ul {
				@include break-max($large1) {
					padding: 0 16px;
				}
			}

			li a {
				@include break-max($large1) {
					padding: 16px 0 16px 2px;
				}
			}

			.order-button-group {
				display: block;
				position: initial;
			}
		}

		&.subnav li {
			margin: 0;

			.icon-arrow-left {
				@include font-size(1.4);
				text-align: left;
				padding-right: 10px;

				&::before {
					display: inline-block;
					@include transform(rotate(90deg));
				}

				@media only screen and (min-width: $lrg-tablet) {
					display: none;
				}
			}

			&.mobile-only:first-child {
				background-color: $warm-white;
			}
		}
	}
}
