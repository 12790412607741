// sass-lint:disable max-file-line-count

.product-page {
	.frame {
		@media only screen and (max-width: $mobile) {
			padding-top: 20px;
		}
	}

	.menu-group {
		position: relative;

		.note {
			@include font-size(1.2);
			background: url("img/arrow-left.png") transparent -34% bottom no-repeat;
			background-size: contain;
			color: $blue;
			font-family: $secondary;
			left: 24%;
			line-height: 20px;
			padding: 0 20px 80px 5%;
			position: absolute;
			text-align: left;
			top: 140px;
			width: 150px;
		}
	}

	.menu-items {
		.flex {
			@include justify-content(flex-start);
		}

		.product {
			text-align: center;

			.title {
				@include font-size(1.6);
				line-height: 28px;
			}

			.item-number {
				@include font-size(1);
				color: $blue;
				display: block;
				font-family: $secondary;
				letter-spacing: 2px;
				line-height: 20px;
				text-transform: uppercase;
			}

			&.sauce {
				height: 400px;

				.the-sauce {
					margin: -50px auto 0;
					position: relative;
					z-index: 5000;
				}
			}

			&.dressing {
				img {
					@include filter(brightness(90%));
					transform: scale(1);
					transition: all 0.2s;

					&:hover {
						@include filter(brightness(101%));
						transform: scale(1.1);
						transition: all 0.2s;
					}
				}
			}
		}
	}
}

.product-focus {
	background: url("img/wood-panel-product.png") transparent center top no-repeat;
	background-size: cover;

	& > .wrapper {
		margin-bottom: 30px;
		position: relative;
	}

	img {
		max-height: 100%;
		max-width: 100%;

		@media only screen and (max-width: $mobile) {
			max-height: 75%;
			max-width: 75%;
		}
	}

	h1 {
		display: inline-block;
		line-height: 1.5;
		margin: 0;
		max-width: $col9;
		position: relative;
		z-index: 200;

		@media only screen and (max-width: $mobile) {
			@include font-size(1.6);
			line-height: 28px;
			max-width: none;
		}
	}

	.price {
		@include font-size(3.2);
		color: $blue;
		font-weight: 200;
		line-height: 1.5;

		@media only screen and (max-width: $mobile) {
			@include font-size(1.6);
			line-height: 28px;
		}
	}

	.item-details {
		text-align: right;
		top: 0;
		position: absolute;
		right: 30px;
		width: 270px;
		z-index: 3000;

		@media only screen and (max-width: $mobile) {
			padding-top: 10px;
			position: static;
			text-align: left;
			width: auto;
		}

		&.na {
			line-height: 1.5;
			text-align: left;
			top: 20px;

			@media only screen and (max-width: $mobile) {
				position: static;
				margin-top: 20px;
				text-align: left;

				p {
					margin: 10px 0;
				}
			}
		}

		.icon-arrow-down {
			@include transition(all 0.5s ease-out 0.5s, all 0.5s ease);

			&.active {
				transform: rotate(-180deg);
				@include transition(all 0.5s ease-out 0.5s, all 0.5s ease);
			}
		}

		.option-select {
			margin-top: 40px;

			@media only screen and (max-width: $mobile) {
				@include font-size(1.4);
				line-height: 20px;
				margin-top: 20px;
			}

			&.userform {
				top: 20px;

				.selectlist {
					background-color: $white;
					padding: 10px 45px 10px 10px;
					width: auto;
				}

				&:hover {
					cursor: pointer;
				}

				.icon-arrow-down {
					top: 8px;
				}
			}
		}

		.label {
			text-align: left;
		}

		.options {
			display: none;
			padding: 10px 0 0;

			a {
				border: 0;
				color: $dkgrey;
				display: block;
				padding: 10px 0;
				text-align: left;

				&.unavailable {
					color: lighten($dkgrey, 30%);

					span {
						@include font-size(1.2);
						display: block;
						line-height: 20px;
					}
				}
			}
		}
	}

	.icon-info {
		@include font-size(2.6);
		// sass-lint:disable variable-for-property
		color: #d3d3d3;
		// sass-lint:enable variable-for-property
		margin-left: 10px;
		overflow: hidden;
		position: relative;

		@media only screen and (max-width: $mobile) {
			@include font-size(1.8);
			margin-left: 0;
		}

		&:hover {
			.tooltip {
				@include transition(all 0.5s ease-out 0.5s, all 0.5s ease);
				height: 36px;
				visibility: visible;
			}
		}
	}

	@media only screen and (max-width: $mobile) {
		background-image: url("img/wood-panel-product-sml.png");
		padding-bottom: 1px;
		padding-top: 30px;
	}
}

.product-details {
	@include align-items(center);
	@include justify-content(space-between);
	line-height: 1.2;
	padding: 0 50px 0 0;

	@media only screen and (max-width: $lrg-tablet) {
		width: $col11;
	}

	@media only screen and (max-width: $mobile) {
		@include flex-direction(column);
		@include align-items(center);
		overflow: hidden;
		margin-top: 25px;
		width: 100%;
	}

	.scroll {
		@include font-size(2.6);
		border: 0;
		color: $grey;
		margin-right: 144px;
		padding-top: 5px;

		@media only screen and (max-width: $mobile) {
			@include order(1);
			margin-right: initial;
		}
	}
}

.nutrition {
	@media only screen and (max-width: $mobile) {
		@include order(2);
		margin: 0 auto;
	}

	ul {
		list-style: none;
		margin: 0 0 10px 20px;
		overflow: hidden;
		padding: 0;

		@media only screen and (max-width: $mobile) {
			margin: 5px 0;
			width: 280px;
			white-space: nowrap;
			overflow-y: hidden;
			overflow-x: scroll;
		}
	}

	li {
		@include font-size(2.8);
		display: inline;
		float: left;
		letter-spacing: 0;
		line-height: 150%;
		margin: 0 20px 0 0;
		padding: 0;

		&.mobile-only {
			@media only screen and (min-width: $lrg-tablet) {
				display: none;
			}
		}

		@media only screen and (max-width: $mobile) {
			display: inline-block;
			float: none;
			text-align: center;
		}

		span {
			font-family: $secondary;
		}
	}

	.measurement {
		@include font-size(1.6);
		line-height: 28px;
	}

	.unit {
		@include font-size(1);
		display: block;
		letter-spacing: 2px;
		line-height: 20px;
		margin: -5px 0 10px;
		text-transform: uppercase;
	}
}

.food-item {
	position: relative;
	z-index: 50;

	@media only screen and (max-width: $mobile) {
		overflow: hidden;
		padding: 0;
		text-align: center;

		img {
			max-height: 100%;
		}

		.co-content {
			margin-bottom: 0;
			min-height: 250px;
		}

		.btn {
			margin: 35px 0;
		}
	}
}

.nutrition-details {
	@include background($white-grad);
	@include transition(all 0.5s ease-out 0.5s, all 0.5s ease);
	display: none;
	line-height: 2;
	opacity: 0;
	position: absolute;
	width: 100%;
	z-index: 0;

	@media only screen and (max-width: $mobile) {
		line-height: 1.5;
		top: 10px;
	}

	h3 {
		@include font-size(1);
		font-family: $secondary;
		letter-spacing: 2px;
		line-height: 20px;
		text-transform: uppercase;
	}

	.wrapper {
		height: 100%;
		position: relative;
	}

	&.shown {
		@include transition(all 0.5s ease-in 0.5s, all 0.5s ease);
		display: block;
		opacity: 1;
		z-index: 5000;

		@media only screen and (max-width: $mobile) {
			background-color: $white;
			z-index: 3000;
		}
	}

	.alt-title {
		.icon-close {
			float: right;
			margin-top: 3px;

			@media only screen and (max-width: $mobile) {
				margin-right: 3px;
			}
		}

		@media only screen and (max-width: $mobile) {
			background-color: $white;
			z-index: 3000;
		}
	}

	.product-details {
		bottom: 111px;
		position: absolute;
		width: 95.85%;

		@media only screen and (max-width: $mobile) {
			display: none;

			.nutrition {
				ul {
					margin: 20px 40px;
				}
			}
		}
	}
}

.calculator {
	width: $col2;
	white-space: nowrap;

	@media only screen and (max-width: $mobile) {
		@include order(3) margin: 15px 0;
		text-align: center;
		width: $col12;
	}
}

.product-description {
	.menu-items {
		background: transparent;
		position: relative;
	}

	h2 {
		@include font-size(10);
		color: $blue;
		letter-spacing: -3px;
		line-height: 130%;
		font-weight: 400;
		margin: 100px 0 70px;
		text-align: center;

		@media only screen and (max-width: $mobile) {
			@include font-size(5);
			margin: 50px 0;
		}

		.lead {
			@include font-size(1);
			color: $grey;
			display: block;
			letter-spacing: 2px;
			line-height: 20px;
			font-family: $secondary;
			text-transform: uppercase;
		}
	}

	&.ordering {
		h2 {
			margin: 20px 0 40px 0;
		}

		p {
			text-align: center;
		}
	}
}

.wood-clean {
	background: url("img/product-wood-clean.jpg") transparent center bottom repeat;
	padding: 190px 0 30px;

	@media only screen and (max-width: $mobile) {
		background-image: url("img/product-wood-clean-sml.jpg");
		padding: 0 0 100px 0;
	}
}

.wood-sauces,
.wood-veggies {
	@extend .wood-clean;
}

.tease {
	@include font-size(1);
	color: $grey;
	font-family: $secondary;
	display: block;
	letter-spacing: 2px;
	line-height: 20px;
	text-align: center;
	text-transform: uppercase;

	.ordering & {
		margin-bottom: 0;
	}
}

.intro {
	@include font-size(2.2);
	line-height: 34px;
	margin: 0 auto 150px;
	text-align: center;
	width: 75%;

	@media only screen and (max-width: $mobile) {
		margin: 0 auto;
		width: auto;
	}
}

.tooltip {
	@include font-size(1.1);
	@include transition(all 0.5s ease-out 0.5s, all 0.5s ease);
	background: url("img/tooltip.png") transparent top left no-repeat;
	background-size: 100%;
	// sass-lint:disable variable-for-property
	color: #918686;
	// sass-lint:enable variable-for-property
	display: inline-block;
	font-family: $secondary;
	height: 0px;
	left: -100px;
	overflow: hidden;
	position: absolute;
	text-align: center;
	top: 35px;
	width: 141px;

	span {
		display: inline-block;
		margin-top: 10px;
	}
}

.tooltip-focus {
	@include font-size(1.1);
	@include transition(all 0.5s ease-out 0.5s, all 0.5s ease);
	background: url("img/tooltip.png") transparent top left no-repeat;
	background-size: 100%;
	// sass-lint:disable variable-for-property
	color: #918686;
	// sass-lint:enable variable-for-property
	display: inline-block;
	font-family: $secondary;
	height: 36px;
	left: -100px;
	overflow: hidden;
	position: absolute;
	text-align: center;
	top: 35px;
	visibility: visible;
	width: 141px;

	span {
		display: inline-block;
		margin-top: 10px;
	}
}

.show-overlay,
.hide-overlay {
	border-bottom: 2px dotted transparent;
	color: $red;

	&:hover {
		border-color: $red;
		cursor: pointer;
	}

	&.mobile-only {
		margin: 20px 0;
	}
}

.swipe {
	display: none;
	margin: 5px 0 20px;

	@media only screen and (max-width: $mobile) {
		display: inline-block;
	}
}

.blue {
	color: $blue;
}

.scroll-content {
	@include font-size(1.2);
	font-family: $secondary;
	height: 50%;
	line-height: 20px;
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 0 100px;

	@media only screen and (max-width: $mobile) {
		padding: 20px 0 0;

		.iphone & {
			padding-top: 0;
			height: 75%;
			width: 100%;
		}
	}
}

.swipe-instructions {
	@include font-size(1.6);
	color: $white;
	line-height: 28px;
	position: absolute;
	z-index: 2000;

	span {
		&:before {
			@include font-size(2);
			margin-right: 10px;
			vertical-align: middle;
		}
	}
}

.fa-circle-o,
.fa-circle {
	font-style: normal;

	&:before {
		@include font-size(1.2);
		color: $red;
		content: "o";
		font-weight: 400;
		font-family: $primary;
		line-height: 20px;
	}
}

.fa-circle {
	&:before {
		@include font-size(2);
		content: "\2022";
		vertical-align: sub;
	}
}

.indicator {
	text-align: center;

	i {
		margin: 0 5px;
	}
}

.companions {
	@include flex-wrap(wrap);
	@include justify-content(center);
	position: relative;

	&.sauces {
		margin: 0 -40px;

		.img__wrap {
			display: none;
		}

		@media only screen and (max-width: $mobile) {
			margin: 0;
		}
	}

	.companion {
		margin: 75px 0 0 $col-margin;
		text-align: center;
		width: $col4;

		&:focus {
			outline: 0;
		}

		@media only screen and (max-width: $mobile) {
			margin: 0;
			position: relative;
		}

		&:first-child,
		&:nth-child(4) {
			margin-left: 0;
		}

		&:first-child,
		&:nth-child(2),
		&:nth-child(3) {
			margin-top: 0;
		}

		&.dressing {
			img {
				max-width: 80%;
			}

			.the-sauce {
				display: none;
			}
		}
	}

	.nutrition {
		background-color: $white;
		position: absolute;
		top: 0;
		width: 100%;

		@media only screen and (max-width: $mobile) {
			position: static;

			ul {
				width: 100%;
			}
		}
	}

	.title {
		@include font-size(1.6);
		line-height: 28px;

		span {
			@include font-size(1);
			color: $blue;
			display: block;
			font-family: $secondary;
			letter-spacing: 2px;
			line-height: 20px;
			margin: 0 0 10px 0;
			text-transform: uppercase;
		}
	}

	img {
		//max-width: 55%;
		@media only screen and (max-width: $mobile) {
			display: block;
			margin: 0 auto;
			max-width: 40%;
		}
	}

	.has-note {
		position: relative;
	}

	.note {
		@include font-size(1.2);
		background: url("img/arrow-left.png") transparent -30px bottom no-repeat;
		background-size: contain;
		color: $blue;
		font-family: $secondary;
		line-height: 20px;
		padding: 0 20px 80px 5%;
		position: absolute;
		right: -30%;
		text-align: left;
		top: 100px;
		width: $col5;

		@media only screen and (max-width: $lrg-tablet) {
			background-position: -15px bottom;
			text-align: right;
			width: $col6;
		}

		@media only screen and (max-width: $mobile) {
			background: url("img/arrow-right.png") transparent left bottom no-repeat;
			background-size: contain;
			left: 5%;
			padding: 0 0 130px 10px;
			right: initial;
			text-align: left;
			width: 120px;
		}
	}

	.mobile-only {
		margin-top: 25px;
	}

	.slick-next,
	.slick-prev {
		z-index: 1000;

		&:before {
			color: $dkgrey;
			font-family: $icon;
		}
	}

	.companion-row {
		position: relative;
		display: flex;
	}

	.slick-next {
		right: 10px;

		&:before {
			content: "\e90e";
		}
	}

	.slick-prev {
		left: 10px;

		&:before {
			content: "\e909";
		}
	}
}

.ordering {
	padding: 100px 0 20px;

	.item {
		margin: 20px $col-margin;

		@media only screen and (max-width: $mobile) {
			margin: 50px 0 100px;
		}

		p {
			font-family: $secondary;
			text-align: left;

			&.title {
				@include font-size(1.6);
				font-family: $primary;
				line-height: 28px;
				margin-bottom: 50px;
				text-align: center;

				span {
					@include font-size(1);
					color: $blue;
					display: block;
					font-family: $secondary;
					letter-spacing: 2px;
					line-height: 20px;
				}
			}
		}
	}

	.flex {
		margin-bottom: 50px;

		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}
}
