.location {
	padding-top: 20px;

	@media only screen and (max-width: $mobile) {
		padding: 0;
	}

	.location-details {
		padding-right: $col-margin;

		@media only screen and (max-width: $mobile) {
			padding: 0 20px;
		}
	}

	.location-frame,
	.map {
		margin-bottom: 25px;
		position: relative;
		max-width: $col7;
		width: $col6;

		iframe {
			border: 0;
			height: 100%;
			position: absolute;
			width: 100%;
		}

		@media only screen and (max-width: $mobile) {
			max-width: none;
			min-height: 300px;
			width: $col12;

			iframe {
				height: 100%;
				width: 100%;
			}
		}
	}

	.location-frame {
		width: $col12;
	}

	.map {
		height: 675px;
		width: $col12;

		@media only screen and (max-width: $mobile) {
			height: 300px;
			overflow: hidden;
		}
	}

	.location-type {
		@include font-size(1);
		font-family: $secondary;
		letter-spacing: 2px;
		line-height: 20px;
		text-transform: uppercase;
	}

	h1 {
		@include font-size(2.8);
		font-weight: 400;
		letter-spacing: 0;
		line-height: 150%;
	}

	.distance {
		@include font-size(1.6);
		float: right;
		font-family: $primary;
		line-height: 28px;

		@media only screen and (max-width: $mobile) {
			display: none;
		}
	}

	.features {
		@include font-size(1);
		font-family: $secondary;
		letter-spacing: 2px;
		line-height: 20px;
		list-style: none;
		margin: 20px 0 0;
		padding: 0;
		text-transform: uppercase;

		li {
			display: inline-block;
			margin-right: 15px;
		}
	}

	.module {
		width: $col6;

		@media only screen and (max-width: $mobile) {
			width: $col12;
		}

		p {
			margin: 0 0 5px 0;
		}
	}

	.location-modules {
		h3 {
			@include font-size(1);
			font-family: $secondary;
			letter-spacing: 2px;
			line-height: 20px;
			text-transform: uppercase;

			@media only screen and (max-width: $mobile) {
				margin-top: 25px;
			}
		}

		.social-icons {
			margin-top: 20px;

			li {
				margin: 5px 10px 0 0;
			}
		}
	}

	.hours {
		dt,
		dd {
			margin: 0 0 8px;
			width: $col6;

			@media only screen and (max-width: $mobile) {
				width: $col12;
			}
		}

		dd {
			@media only screen and (max-width: $mobile) {
				margin-bottom: 20px;
			}
		}
	}

	.btn {
		margin: 30px 0;
	}

	@media only screen and (max-width: $mobile) {
		@include flex-direction(column);

		.location-frame {
			order: 2;
		}

		.map {
			margin-bottom: 30px;
			order: 1;
		}

		.social-icons {
			padding: 10px 0;
			text-align: left;

			li {
				margin: 0 5px;
			}
		}
	}
}

.location-results {
	height: 850px;
	overflow: hidden;
	position: relative;
	padding: 0;

	@media only screen and (max-width: $mobile) {
		overflow-y: scroll;
	}

	iframe {
		border: 0;
		height: 100%;
		position: absolute;
		width: 100%;
	}
}

.location-listings {
	article {
		padding-top: 50px;

		ul {
			@include flex-direction(column);
			@include flex-wrap(wrap);
			height: 800px;
			display: flex;

			@media only screen and (max-width: $mobile) {
				display: block;
				height: auto;
			}

			li {
				width: $col4;
			}
		}
	}

	.location {
		h2 {
			margin: 0;
		}
	}
}
