figure {
	display: table;
	margin-bottom: 50px;

	@media only screen and (max-width: $mobile) {
		margin: 0 -15px 50px;
	}

	&.has-photographer {
		position: relative;

		.photographer {
			@include font-size(1.2);
			bottom: 50px;
			color: $white;
			font-family: $secondary;
			letter-spacing: 2.4px;
			line-height: 20px;
			position: absolute;
			right: 20px;
			text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
			text-transform: uppercase;
		}
	}

	&.left,
	&.right {
		max-width: $col5;

		@media only screen and (max-width: $mobile) {
			max-width: none;
			width: 100%;

			img {
				display: block;
				margin: 0 auto;
				width: 100%;
			}
		}
	}

	&.left {
		float: left;
		margin: 0 $col-margin 20px 0;

		@media only screen and (max-width: $mobile) {
			float: none;
		}
	}

	&.right {
		float: right;
		margin: 0 0 20px $col-margin;

		@media only screen and (max-width: $mobile) {
			float: none;
		}
	}

	figcaption {
		@include font-size(1.2);
		display: table-caption;
		caption-side: bottom;
		line-height: 20px;
		margin: 5px 0;

		@media only screen and (max-width: $mobile) {
			margin: 10px 25px;
		}
	}
}
