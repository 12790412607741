// sass-lint:disable no-ids, id-name-format, no-qualifying-elements

.footer {
	background: $white;
	position: relative;
	z-index: 30;

	.background-image img {
		min-height: 475px;
		width: 100%;
	}
}

.footer-modules {
	@include justify-content(space-between);
	background-color: $white;
	display: flex;
	padding: 30px 25px;

	@media only screen and (max-width: $sml-desktop) {
		display: block;
		padding: 30px 10px;
		text-align: center;
	}
}

.footer-nav {
	@media only screen and (min-width: $sml-desktop) {
		display: flex;
		justify-content: space-between;
		flex-basis: 100%;
	}

	@media only screen and (max-width: $sml-desktop) {
		text-align: center;
	}

	ul {
		margin: 0;
		padding: 0;
		position: relative;
	}

	li {
		@include link-underline();
		display: inline-block;
		margin-right: 25px;
		font-weight: $medium-weight;

		@media only screen and (max-width: $mobile) {
			display: block;
			margin: 0;
			padding: 10px 0;

			a {
				display: block;
			}
		}
	}

	ul.footer-sub-nav {
		@include font-size(1.2);
		line-height: 20px;

		@media only screen and (max-width: $sml-desktop) {
			margin: 30px 0 0 0;
			position: relative;
		}

		li {
			cursor: auto;
			margin-right: 15px;
			white-space: nowrap;
			font-weight: $regular-weight;

			@media only screen and (max-width: $mobile) {
				margin-right: 0;
				white-space: normal;
			}

			&:hover {
				text-decoration: none;
			}
		}

		a {
			@include link-underline();
			color: $grey;
			font-weight: 600;

			&:hover {
				border-color: $grey;

				@media only screen and (max-width: $mobile) {
					border-color: transparent;
				}
			}
		}
	}

	[class^="icon-arrow-"],
	[class*=" icon-arrow-"] {
		@include font-size(1.2);
		line-height: 20px;
		margin-left: 5px;
	}

	.active {
		[class^="icon-arrow-"],
		[class*=" icon-arrow-"] {
			&:before {
				@include transform(rotate(180deg));
				display: inline-block;
			}
		}
	}
}

// pixel fix
iframe#braintreeDataFrame {
	position: absolute;
	left: 0;
	bottom: 0;
}
