.home-menu {
	@include font-size(1.6);
	background-color: $white;
	line-height: 28px;
	text-align: center;
	padding: 10px 0;

	@media only screen and (max-width: $mobile) {
		display: none;
	}

	&.fixed {
		background-color: $white;
		padding: 14px 0;
		position: fixed;
		top: 61px;
		width: 100%;
		z-index: 1000;

		a {
			background-image: none;
			padding-bottom: 1px;
			transition: all 0s ease;

			&.current {
				background: url("img/menu-underline.png") transparent bottom left
					repeat-x;
				transition: all 0s ease;
			}
		}
	}

	@media only screen and (max-width: $sml-desktop) {
		@include font-size(1.4);
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 1.57%;

		&:last-child {
			border-left: 1px solid $faint-grey;
			margin-left: 0;
			padding-left: 1.57%; // same padding-left as margin on all li's above
		}
	}

	h1 {
		font-size: 1.6rem;
		margin: 0;
	}
}

.menu-group {
	.sauces-dressing &,
	.catering & {
		padding-top: 25px;

		&:nth-child(odd) {
			background-color: $bkgrey;
		}

		h2 {
			@include unhidden();
			@include font-size(3.6);
			letter-spacing: -0.5px;
			line-height: 140%;
		}
	}

	&.no-accordion {
		h2 {
			a {
				display: block;
			}

			&:after {
				display: none;
			}
		}
	}

	.catering & {
		padding-top: 50px;
	}

	.load {
		margin-bottom: 0;
		padding: 0 0 40px 0;
		text-align: center;
	}

	h2 {
		@include font-size(2.8);
		border-bottom: 1px solid $yellow;
		color: $red;
		letter-spacing: 0;
		line-height: 150%;
		margin: 0;
		padding: 25px;

		@media only screen and (min-width: $lrg-tablet) {
			@include visuallyhidden();
		}

		.sauces-dressing &,
		.catering & {
			@include unhidden();
			color: $blue;

			&:after {
				display: none;
			}
		}

		&:after {
			@include font-size(1.8);
			content: "\e902";
			font-family: $icon;
			float: right;
			margin: 3px;
		}

		&.active {
			&:after {
				@include transform(rotate(180deg));
			}
		}
	}
}

.menu-items,
.story-items,
.restaurant-items {
	background-color: $bkgrey;
	padding: 100px 30px;

	@media only screen and (max-width: $mobile) {
		padding: 0 10px;
	}

	.flex {
		@include justify-content(space-around);
		max-width: $main;
		margin: 0 auto;
		width: $col12;

		@media only screen and (max-width: $mobile) {
			display: block;
		}
	}

	.item {
		@include font-size(2.4);
		letter-spacing: -0.5px;
		line-height: 30px;
		margin-left: $col-margin;
		text-align: center;
		width: $col4;

		.sauces-dressing & {
			@include font-size(1.6);
			line-height: 28px;
			text-align: left;

			@media only screen and (max-width: $mobile) {
				position: relative;
			}

			h3 {
				@include font-size(2.4);
				letter-spacing: -0.5px;
				line-height: 30px;
			}

			.description {
				padding: 0 40px;
			}
		}

		.catering & {
			margin-bottom: 30px;

			a {
				@include font-size(2.4);
				color: $grey;
				font-weight: 400;
				letter-spacing: -0.5px;
				line-height: 30px;
			}
		}

		@media only screen and (max-width: $mobile) {
			margin-left: 0;
			padding: 10px 0;
			text-align: left;
			width: auto;
		}

		&:first-child {
			margin-left: 0;
		}

		sup {
			@include font-size(1.4);
		}

		a {
			&:hover {
				border-color: transparent;
			}

			span {
				@include hoverlines;
				color: $grey;

				&:hover {
					color: $red;
				}

				&.food-group {
					@include item-details();
				}
			}
		}
	}

	img {
		max-width: 100%;
		width: 100%;
	}
}

.menu-items {
	padding-bottom: 30px;
	padding-top: 30px;

	.product {
		@media only screen and (min-width: $mobile) {
			display: block;
			height: 400px;
			overflow: hidden;

			img {
				max-height: 100%;
			}

			&.sauce {
				height: 300px;
			}

			&.food {
				border: 0;
			}
		}
	}

	.sauces-dressing &,
	.catering & {
		background-color: transparent;
		position: relative;

		.flex {
			@include justify-content(flex-start);
		}

		.dressing {
			height: auto;

			.the-sauce {
				display: none;
			}

			@media only screen and (min-width: $mobile) {
				img {
					@include filter(brightness(90%));
					transform: scale(1);
					transition: all 0.2s;

					&:hover {
						@include filter(brightness(101%));
						transform: scale(1.1);
						transition: all 0.2s;
					}
				}
			}
		}
	}
}

.sauce-overlay {
	@include font-size(1.4);
	background: rgba(white, 0.9);
	box-sizing: border-box;
	display: none;
	font-family: $secondary;
	height: 100%;
	left: 0;
	padding: 40px 20px;
	position: absolute;
	top: 20px;
	width: 100%;
	z-index: 15000;

	@media only screen and (max-width: $mobile) {
		padding: 20px;
		top: 0px;
	}

	.icon-close {
		@include font-size(1.8);
		border: 0;
		color: $red;
		float: right;

		@media only screen and (max-width: $mobile) {
			float: none;
			position: absolute;
			right: 10px;
			top: 10px;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.hide-overlay,
	.calculator {
		@include font-size(1.6);
		border: 0;
		font-family: $primary;
		line-height: 28px;
	}

	.title {
		@include font-size(2.4);
		font-family: $primary;
		letter-spacing: -0.5px;
		line-height: 30px;
	}

	.small {
		@include font-size(1);
		letter-spacing: 2px;
		line-height: 20px;
		text-transform: uppercase;
	}

	.product-details {
		@include justify-content(space-between);
		margin-top: 40px;
		max-width: none;
		width: auto;

		@media only screen and (max-width: $mobile) {
			margin-top: 0;
		}

		&.flex {
			@include justify-content(space-between);
		}
	}

	.sauce-details {
		margin: 0 20px;
	}

	.swipe {
		display: block;
		text-align: center;
	}
}

.product,
.companion {
	.the-sauce {
		background-repeat: no-repeat;
		box-sizing: content-box;
		margin-left: 50px;
		max-height: 303px;
		max-width: 250px;
		overflow: hidden;
		width: 100%;
		height: 100%;

		@media only screen and (max-width: 1081px) {
			@include transform(scale(0.9));
			max-width: 200px;
			padding-right: 52px;
		}

		@media only screen and (max-width: $mobile) {
			@include transform(scale(1));
			max-width: 200px;
			margin: 0 auto;
		}
	}
}

.companion,
.menu-items {
	.the-sauce {
		height: 300px;
		margin: 0 auto;

		&:hover {
			cursor: pointer;
		}
	}

	.sauce-overlay {
		height: 500px;
		text-align: left;
		width: 100%;

		@media only screen and (max-width: $mobile) {
			background: $white;
			height: auto;
			font-weight: normal;

			.product-details {
				padding: 0;
			}
		}
	}

	.calculator {
		display: none;
	}
}

.followMeBar {
	background-color: $white;
	position: relative;
	z-index: 1;
}

.followMeBar.fixed {
	position: fixed;
	top: 50px;
	width: 100%;
	box-sizing: border-box;
	z-index: 110;
}

.followMeBar.fixed.absolute {
	position: absolute;
}
