.sunday-video {
	@extend .emergency-notice;
	background-color: $black;
	height: 100%;
	padding-bottom: 0;
	z-index: 5000;

	@media only screen and (max-width: $mobile) {
		.wrapper {
			padding: 0 30px;
		}
	}

	h1 {
		@include font-size(3.6);
		letter-spacing: -0.5px;
		line-height: 140%;
		margin-bottom: 10px;
	}

	h2 {
		@include font-size(1.2);
		font-family: $secondary;
		margin-bottom: 20px;
	}

	.close {
		@include font-size(1.6);
		color: $grey;
		font-family: $secondary;
		display: inline-block;
		line-height: 20px;
		margin: 20px 40px;
		position: absolute;
		z-index: 1000;
	}

	.icon-close {
		color: $red;
		display: inline-block;
		margin-right: 10px;
	}

	.wrapper {
		max-width: none;
	}

	.vid-wrapper {
		padding-top: 75px;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.post-video {
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%;

	.wrapper {
		@include align-items(center);
		@include font-size(4.8);
		background: rgba(black, 0.75);
		color: $white;
		display: flex;
		height: 100%;
		letter-spacing: -0.5px;
		line-height: 130%;
		padding: 0;

		@media only screen and (max-width: $mobile) {
			@include font-size(2.4);
			font-weight: 700;
			letter-spacing: -0.5px;
			line-height: 30px;
		}
	}

	.content {
		margin: 0 auto;
		padding: 0 30px;
	}

	.btn {
		@include font-size(2.2);
		line-height: 34px;
		margin: 0 20px;
		padding: 20px 40px 15px;

		@media only screen and (max-width: $mobile) {
			display: block;
			margin: 20px;
		}
	}

	.replay {
		@include font-size(2.2);
		color: $white;
		line-height: 34px;
		margin-top: 80px;

		.icon-replay {
			@include font-size(3);
			font-weight: 600;
			margin-right: 5px;
			vertical-align: sub;
		}
	}
}
